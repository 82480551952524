import {
  catchError,
  defer,
  Observable,
  share,
  Subject,
  Subscription,
  throwError,
} from "rxjs";
import {
  first,
  mapTo,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { Inject, Service } from "typedi";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { PAYMENT_BRAND } from "../../../application/core/models/constants/PaymentBrand";
import { PAYMENT_EVENTS } from "../../../application/core/models/constants/PaymentEvents";
import { IMessageBusEvent } from "../../../application/core/models/IMessageBusEvent";
import { type IInternalsMonitor } from "../../../application/core/services/monitoring/IInternalsMonitor";
import { CardinalError } from "../../../application/core/services/st-codec/CardinalError";
import { ActionCode } from "../../../application/core/services/three-d-verification/implementations/cardinal-commerce/data/ActionCode";
import { IVerificationData } from "../../../application/core/services/three-d-verification/implementations/cardinal-commerce/data/IVerificationData";
import { IVerificationResult } from "../../../application/core/services/three-d-verification/implementations/cardinal-commerce/data/IVerificationResult";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { ENVIRONMENT } from "../../../environments/environment";
import { IConfig } from "../../../shared/model/config/IConfig";
import { ConfigProvider } from "../../../shared/services/config-provider/ConfigProvider";
import { InterFrameCommunicator } from "../../../shared/services/message-bus/InterFrameCommunicator";
import { ofType } from "../../../shared/services/message-bus/operators/ofType";
import { ResourceLoadingTimeoutHandler } from "../../../shared/services/utils/ResourceLoadingTimeoutHandler";
import { CardinalProvider } from "./CardinalProvider";
import { IInitializationData } from "./data/IInitializationData";
import { ITriggerData } from "./data/ITriggerData";
import { IValidationResult } from "./data/IValidationResult";
import { ICardinal } from "./ICardinal";

@Service()
export class CardinalClient {
  private static readonly cardinalValidationError = 4000;
  private cardinal$: Observable<ICardinal>;
  private threeDPopupCancel$: Subject<void>;
  private destroy$: Observable<IMessageBusEvent<unknown>>;
  private setupComplete$: Observable<void>;

  constructor(
    private interFrameCommunicator: InterFrameCommunicator,
    private messageBus: IMessageBus,
    private cardinalProvider: CardinalProvider,
    private configProvider: ConfigProvider,
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
    private resourceTimeoutHandler: ResourceLoadingTimeoutHandler,
  ) {
    this.cardinal$ = defer(() =>
      this.configProvider.getConfig$().pipe(
        switchMap((config: IConfig) =>
          this.cardinalProvider.getCardinal$(
            Boolean(Number(config.livestatus)),
          ),
        ),

        this.resourceTimeoutHandler.captureAndReportResourceLoadingTimeout(
          "Cardinal script load timeout",
        ),
        catchError((error) => {
          if (error && error.message) {
            error.message = `Unspecific Cardinal Error: ${error.message}`;
          }
          this.internalMonitor.recordIssue(error, {
            function: "cardinalLoad",
          });
          return throwError(() => error);
        }),

        share(),
      ),
    );

    this.threeDPopupCancel$ = new Subject<void>();
    this.destroy$ = this.messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY));
  }

  init(): void {
    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.CARDINAL_SETUP)
      .thenRespond((event: IMessageBusEvent<IInitializationData>) =>
        this.cardinalSetup(event.data),
      );

    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.CARDINAL_CONTINUE)
      .thenRespond((event: IMessageBusEvent<IVerificationData>) =>
        this.cardinalContinue(event.data),
      );

    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.CARDINAL_TRIGGER)
      .thenRespond((event: IMessageBusEvent<ITriggerData<unknown>>) =>
        this.cardinalTrigger(event.data),
      );

    this.messageBus
      .pipe(ofType(PUBLIC_EVENTS.THREED_CANCEL), takeUntil(this.destroy$))
      .subscribe(() => this.cancelThreeDProcess());
  }

  private cardinalSetup(data: IInitializationData): Observable<void> {
    if (!this.setupComplete$) {
      this.setupComplete$ = this.cardinal$.pipe(
        switchMap(
          (cardinal: ICardinal) =>
            new Observable<void>((subscriber) => {
              cardinal.on(PAYMENT_EVENTS.SETUP_COMPLETE, () => {
                subscriber.next(void 0);
                subscriber.complete();
                cardinal.off(PAYMENT_EVENTS.SETUP_COMPLETE);
              });
              cardinal.configure(ENVIRONMENT.CARDINAL_COMMERCE.CONFIG);
              cardinal.setup(PAYMENT_EVENTS.INIT, {
                jwt: data.jwt,
              });
            }),
        ),
        catchError((error) => {
          if (error && error.message) {
            error.message = `Unspecific Cardinal Error: ${error.message}`;
          }
          this.internalMonitor.recordIssue(error, {
            function: "cardinalSetup",
          });
          return throwError(() => error);
        }),
        shareReplay(1),
      );
    }

    return this.setupComplete$;
  }

  private cardinalContinue(
    data: IVerificationData,
  ): Observable<IVerificationResult> {
    return this.cardinal$.pipe(
      switchMap(
        (cardinal: ICardinal) =>
          new Observable<IVerificationResult>((subscriber) => {
            const cancelSubscription: Subscription =
              this.threeDPopupCancel$.subscribe(() => {
                subscriber.next({
                  validated: false,
                  actionCode: ActionCode.FAILURE,
                  errorNumber: 4001,
                  errorDescription: "3DS process has been cancelled",
                });
              });

            cardinal.on(
              PAYMENT_EVENTS.VALIDATED,
              (result: IValidationResult, responseJwt: string) => {
                if (
                  result.ErrorNumber !== CardinalClient.cardinalValidationError
                ) {
                  if (result.ActionCode === ActionCode.ERROR) {
                    this.internalMonitor.recordIssue(
                      new CardinalError(result.ErrorDescription, result),
                      { function: "cardinalValidation" },
                    );
                  }

                  subscriber.next({
                    validated: result.Validated,
                    actionCode: result.Payment?.ExtendedData?.ChallengeCancel // If this is present, it means the user has closed the ACS challenge window.
                      ? ActionCode.CANCELLED
                      : result.ActionCode,
                    errorNumber: result.ErrorNumber,
                    errorDescription: result.ErrorDescription,
                    jwt: responseJwt,
                  });
                  subscriber.complete();
                  cardinal.off(PAYMENT_EVENTS.VALIDATED);
                  cancelSubscription.unsubscribe();
                }
              },
            );

            const { acsUrl, payload, transactionId, jwt } = data;
            cardinal.continue(
              PAYMENT_BRAND,
              {
                AcsUrl: acsUrl,
                Payload: payload,
              },
              {
                Cart: [],
                OrderDetails: {
                  TransactionId: transactionId,
                },
              },
              jwt,
            );
          }),
      ),
      catchError((error) => {
        if (error && error.message) {
          error.message = `Unspecifed Cardinal Error: ${error.message}`;
        }
        this.internalMonitor.recordIssue(error, {
          function: "cardinalContinue",
        });
        return throwError(() => error);
      }),
    );
  }

  private cardinalTrigger<T>(triggerData: ITriggerData<T>): Observable<void> {
    const { eventName, data } = triggerData;

    return this.cardinal$.pipe(
      first(),
      tap((cardinal) => cardinal.trigger(eventName, data)),
      mapTo(void 0),
    );
  }

  private cancelThreeDProcess(): void {
    this.threeDPopupCancel$.next();
    const cardinalElementContainer = document.getElementById(
      "Cardinal-ElementContainer",
    );

    if (!cardinalElementContainer) {
      return;
    }

    cardinalElementContainer.parentNode.removeChild(cardinalElementContainer);
  }
}
