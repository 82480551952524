import { Service } from "typedi";
import { IEnhancedApplePayPaymentRequest } from "./IEnhancedApplePayPaymentRequest";
import { IApplePaySession } from "./IApplePaySession";

@Service()
export abstract class IApplePayNamespace {
  readonly STATUS_SUCCESS: number;
  readonly STATUS_FAILURE: number;
  readonly STATUS_INVALID_BILLING_POSTAL_ADDRESS: number;
  readonly STATUS_INVALID_SHIPPING_POSTAL_ADDRESS: number;
  readonly STATUS_INVALID_SHIPPING_CONTACT: number;
  readonly STATUS_PIN_INCORRECT: number;
  readonly STATUS_PIN_LOCKOUT: number;
  readonly STATUS_PIN_REQUIRED: number;
  abstract isApplePaySessionAvailable(): boolean;
  abstract canMakePayments(): boolean;
  abstract canMakePaymentsWithActiveCard(merchantId: string): Promise<boolean>;
  abstract supportsVersion(version: number): boolean;
  abstract createInstance(
    applePayVersion: number,
    applePayPaymentRequest: IEnhancedApplePayPaymentRequest,
  ): IApplePaySession;
  abstract getLatestSupportedApplePayVersion(): number;
}
