import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IStartPaymentMethod } from "../../../application/core/services/payments/events/IStartPaymentMethod";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { PayPalPaymentMethodName } from "../constants/PayPalConstants";
import { IPayPalResolveParameter } from "../models/IPayPalResolveParameter";
import { IPayPalCallbackResolveAction } from "../models/IPayPalCallbackResolveAction";
import { IPayPalGatewayRequest } from "../models/IPayPalPayRequest";

/**
 * The action to execute when the merchant developer calls `.resolve()` on the action
 * that's returned as part of the onConfirmPayment callback.
 */
export class SubmitOrderResolver implements IPayPalCallbackResolveAction {
  constructor(private readonly messageBus: IMessageBus) {}
  public resolve(param?: IPayPalResolveParameter) {
    this.messageBus.publish<IStartPaymentMethod<IPayPalGatewayRequest>>({
      type: PUBLIC_EVENTS.START_PAYMENT_METHOD,
      data: {
        data: {
          jwt: param?.jwt,
        },
        name: PayPalPaymentMethodName,
      },
    });
  }
}
