import { FUNDING_SOURCE } from "@paypal/paypal-js";

export class PayPalFundingMock {
  public mockGetFundingSources = (): ReadonlyArray<FUNDING_SOURCE> => [
    "paypal",
    "paylater",
  ];

  public mockIsFundingEligible = (): boolean => true;

  public mockRememberFunding = (
    fundingSource: ReadonlyArray<FUNDING_SOURCE>,
  ): void => {
    // Do nothing
  };

  // Returns a record mapping strings to funding sources
  public mockFunding = (): Record<string, FUNDING_SOURCE> => ({
    paypal: "paypal",
    credit: "credit",
    paylater: "paylater",
  });
}
