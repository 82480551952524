import { FUNDING_SOURCE } from "@paypal/paypal-js";
import {
  PayLaterFundingSourceName,
  PayPalFundingSourceName,
} from "../constants/PayPalConstants";

export class PayPalFundingSourceUtils {
  public static find(fundingSource: string | undefined): FUNDING_SOURCE {
    const all = this.getSupportedSources();
    const index = all.map((x) => x.toString()).indexOf(fundingSource ?? "");

    return index >= 0 ? all[index] : undefined;
  }

  public static getSupportedSources(): FUNDING_SOURCE[] {
    return [this.getDefault(), PayLaterFundingSourceName];
  }

  public static getDefault(): FUNDING_SOURCE {
    return PayPalFundingSourceName;
  }

  /**
   * These are the funding sources that can be disabled on the PayPal config.
   */
  public static getDisableableSources(): FUNDING_SOURCE[] {
    return [
      "venmo",
      "credit",
      "paylater",
      "card",
      "ideal",
      "sepa",
      "bancontact",
      "giropay",
      "sofort",
      "eps",
      "mybank",
      "p24",
      "blik",
      "mercadopago",
    ];
  }

  public static getCreditFundingSources(): FUNDING_SOURCE[] {
    return ["credit", "paylater"];
  }
}
