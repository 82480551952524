import { Token } from "typedi";
import { IConfig } from "../model/config/IConfig";
import { IMessageSubscriber } from "../services/message-bus/interfaces/IMessageSubscriber";
import { IMessageBus } from "../../application/core/shared/message-bus/IMessageBus";
import { IReducer } from "../../application/core/store/IReducer";
import { IStore } from "../../application/core/store/IStore";
import { ITranslator } from "../../application/core/shared/translator/ITranslator";
import { ConfigProvider } from "../services/config-provider/ConfigProvider";
import { ITranslationChangeTracker } from "../../application/core/shared/translator/ITranslationChangeTracker";

export const WINDOW = new Token<Window>("window");
export const CONFIG = new Token<IConfig>("config");
export const MESSAGE_SUBSCRIBER_TOKEN = new Token<IMessageSubscriber>(
  "message-subscriber",
);
export const MESSAGE_BUS_TOKEN = new Token<IMessageBus>("message-bus");
// @todo(typings) Not sure if we could find the type generic enough to cover all the usages safely.

export const REDUCER_TOKEN = new Token<IReducer<any>>("reducer");
export const STORE_TOKEN = new Token<IStore<unknown>>("store");
export const TRANSLATOR_TOKEN = new Token<ITranslator>("translator");
export const CONFIG_PROVIDER_TOKEN = new Token<ConfigProvider>(
  "config-provider",
);
export const TRANSLATION_CHANGE_TRACKER_TOKEN =
  new Token<ITranslationChangeTracker>("translation-change-tracker");
