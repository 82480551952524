import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Inject, Service } from "typedi";
import { MESSAGE_SUBSCRIBER_TOKEN } from "../../../../shared/dependency-injection/InjectionTokens";
import { IMessageSubscriber } from "../../../../shared/services/message-bus/interfaces/IMessageSubscriber";
import { ofType } from "../../../../shared/services/message-bus/operators/ofType";
import { ofTypeList } from "../../../../shared/services/message-bus/operators/ofTypeList";
import { untilDestroy } from "../../../../shared/services/message-bus/operators/untilDestroy";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { IMessageBusEvent } from "../../models/IMessageBusEvent";
import { IMessageBus } from "../../shared/message-bus/IMessageBus";
import { type IInternalsMonitor } from "./IInternalsMonitor";
import { InternalsMonitorActivityCategories } from "./InternalsMonitorActivityCategories";
import { IInternalMonitorMessageBusData } from "./IInternalMonitorMessageBusData";
import { IInternalsMonitorGlobalActivityTrackerConfig } from "./IInternalsMonitorGlobalActivityTrackerConfig";
import { INTERNALS_MONITOR_GLOBAL_ACTIVITY_TRACKLING_LIST } from "./InternalsMonitorGlobalActivityTrackerConfig";

@Service({ id: MESSAGE_SUBSCRIBER_TOKEN, multiple: true })
export class InternalsMonitorGlobalActivityTracker
  implements IMessageSubscriber
{
  constructor(
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
  ) {}

  register(messageBus: IMessageBus): void {
    const destroy$: Observable<void> = messageBus.pipe(
      ofType(PUBLIC_EVENTS.DESTROY),
      untilDestroy(messageBus),
    );
    const eventsToWatch: string[] = Object.keys(
      INTERNALS_MONITOR_GLOBAL_ACTIVITY_TRACKLING_LIST,
    );

    messageBus
      .pipe(ofTypeList(eventsToWatch), takeUntil(destroy$))
      .subscribe((event: IMessageBusEvent<IInternalMonitorMessageBusData>) => {
        const eventConfig: IInternalsMonitorGlobalActivityTrackerConfig =
          INTERNALS_MONITOR_GLOBAL_ACTIVITY_TRACKLING_LIST[event.type];
        if (event?.type === PUBLIC_EVENTS.UPDATE_JWT) {
          this.internalMonitor.recordActivity(
            InternalsMonitorActivityCategories.JWT_UPDATES,
          );
        } else {
          const context = eventConfig.message(
            event?.data?.name || event?.data?.customMessage,
          );
          this.internalMonitor.recordActivity(
            eventConfig.sentryBreadcrumbsCategories,
            { context },
          );
        }
      });
  }
}
