import { PUBLIC_EVENTS } from "./EventTypes";

export enum ExposedEventsName {
  SUCCESS = "success",
  ERROR = "error",
  SUBMIT = "submit",
  CANCEL = "cancel",
  JWT_REQUEST = "jwtRequest",
  JWT_RESPONSE = "jwtResponse",
  GATEWAY_REQUEST = "stGatewayRequest",
  GATEWAY_RESPONSE = "stGatewayResponse",
  JWT_ERROR = "jwtError",
  PAYMENT_BEFORE = "paymentPreCheck",
  PAYMENT_INIT_STARTED = "paymentInitStarted",
  PAYMENT_INIT_COMPLETED = "paymentInitCompleted",
  PAYMENT_INIT_FAILED = "paymentInitFailed",
  PAYMENT_STARTED = "paymentStarted",
  PAYMENT_COMPLETED = "paymentCompleted",
  PAYMENT_FAILED = "paymentFailed",
  PAYMENT_CANCELED = "paymentCanceled",
}

export const EXPOSED_EVENTS = {
  cancel: PUBLIC_EVENTS.CALL_MERCHANT_CANCEL_CALLBACK,
  success: PUBLIC_EVENTS.CALL_MERCHANT_SUCCESS_CALLBACK,
  error: PUBLIC_EVENTS.CALL_MERCHANT_ERROR_CALLBACK,
  submit: PUBLIC_EVENTS.CALL_MERCHANT_SUBMIT_CALLBACK,
  jwtRequest: PUBLIC_EVENTS.TRANSPORT_JWT_REQUEST,
  jwtResponse: PUBLIC_EVENTS.TRANSPORT_JWT_RESPONSE,
  stGatewayRequest: PUBLIC_EVENTS.TRANSPORT_GATEWAY_REQUEST,
  stGatewayResponse: PUBLIC_EVENTS.TRANSPORT_GATEWAY_RESPONSE,
  jwtError: PUBLIC_EVENTS.TRANSPORT_JWT_ERROR,
  paymentPreCheck: PUBLIC_EVENTS.PAYMENT_METHOD_PRE_CHECK,
  paymentInitStarted: PUBLIC_EVENTS.PAYMENT_METHOD_INIT_STARTED,
  paymentInitCompleted: PUBLIC_EVENTS.PAYMENT_METHOD_INIT_COMPLETED,
  paymentInitFailed: PUBLIC_EVENTS.PAYMENT_METHOD_INIT_FAILED,
  paymentStarted: PUBLIC_EVENTS.PAYMENT_METHOD_STARTED,
  paymentCompleted: PUBLIC_EVENTS.PAYMENT_METHOD_COMPLETED,
  paymentFailed: PUBLIC_EVENTS.PAYMENT_METHOD_FAILED,
  paymentCanceled: PUBLIC_EVENTS.PAYMENT_METHOD_CANCELED,
};
