/* istanbul ignore file */
import { Inject, Service } from "typedi";
import { IEnhancedApplePayPaymentRequest } from "../models/apple-pay-payment-data/IEnhancedApplePayPaymentRequest";
import { IApplePaySession } from "../models/IApplePaySession";
import { IApplePayNamespace } from "../models/IApplePayNamespace";
import { WINDOW } from "../../../../shared/dependency-injection/InjectionTokens";
import { MockedApplePaySession } from "./MockedApplePaySession";

@Service()
export class MockedApplePayNamespace implements IApplePayNamespace {
  constructor(@Inject(WINDOW) private window: IWindow) {
    // Hack because when using official applepay, the session gets added to window
    // and we access statuses via ApplePayStatus.
    window.ApplePaySession = {
      STATUS_SUCCESS: 1,
      STATUS_FAILURE: 2,
    };
  }
  STATUS_SUCCESS: number;
  STATUS_FAILURE: number;
  STATUS_INVALID_BILLING_POSTAL_ADDRESS: number;
  STATUS_INVALID_SHIPPING_POSTAL_ADDRESS: number;
  STATUS_INVALID_SHIPPING_CONTACT: number;
  STATUS_PIN_INCORRECT: number;
  STATUS_PIN_LOCKOUT: number;
  STATUS_PIN_REQUIRED: number;

  isApplePaySessionAvailable(): boolean {
    return true;
  }

  canMakePayments(): boolean {
    return true;
  }

  canMakePaymentsWithActiveCard(merchantId: string): Promise<boolean> {
    return Promise.resolve(true);
  }

  supportsVersion(version: number): boolean {
    return true;
  }

  createInstance(
    applePayVersion: number,
    applePayPaymentRequest: IEnhancedApplePayPaymentRequest,
  ): IApplePaySession {
    return new MockedApplePaySession(applePayPaymentRequest);
  }

  getLatestSupportedApplePayVersion(): number {
    return 12;
  }
}
