import { Subject } from "rxjs";

import { IPayPalOnClickActions } from "../models/IPayPalOnClickActions";
import { PayPalClickResult } from "../models/PayPalClickResult";

/**
 * The actions that can be provided to the merchant developer when
 * any of the PayPal buttons are clicked.
 */
export class PayPalOnClickActions implements IPayPalOnClickActions {
  constructor(private readonly notification: Subject<PayPalClickResult>) {}

  public reject(): void {
    this.notification.next(PayPalClickResult.REJECTED);
    this.notification.complete();
  }

  public resolve() {
    this.notification.next(PayPalClickResult.PROCEED);
    this.notification.complete();
  }
}
