import { ENVIRONMENT } from "../../../../../environments/environment";
import { IConfig } from "../../../../../shared/model/config/IConfig";
import { MERCHANT_FORM_SELECTOR } from "../Selectors";
import {
  DefaultTokenizedCardPaymentConfig,
  TokenizedCardPaymentConfigName,
} from "../../../../../integrations/tokenized-card/models/ITokenizedCardPaymentMethod";
import { DEFAULT_FIELDS_TO_SUBMIT } from "./DefaultFieldsToSubmit";
import { DEFAULT_SUBMIT_FIELDS } from "./DefaultSubmitFields";
import { DEFAULT_COMPONENTS_IDS } from "./DefaultComponentsIds";
import { DEFAULT_COMPONENTS } from "./DefaultComponents";
import { DEFAULT_PLACEHOLDERS } from "./DefaultPlaceholders";
import { DEFAULT_INIT } from "./DefaultInit";

export const DEFAULT_CONFIG: IConfig = {
  analytics: false,
  animatedCard: false,
  applePay: undefined,
  buttonId: "",
  stopSubmitFormOnEnter: false,
  cancelCallback: null,
  componentIds: DEFAULT_COMPONENTS_IDS,
  components: DEFAULT_COMPONENTS,
  datacenterurl: ENVIRONMENT.GATEWAY_URL,
  deferInit: false,
  disableNotification: false,
  errorCallback: null,
  errorReporting: true,
  fieldsToSubmit: DEFAULT_FIELDS_TO_SUBMIT,
  formId: MERCHANT_FORM_SELECTOR,
  init: DEFAULT_INIT,
  jwt: "",
  livestatus: 0,
  origin: window.location.origin,
  panIcon: false,
  placeholders: DEFAULT_PLACEHOLDERS,
  styles: {},
  submitCallback: null,
  submitFields: DEFAULT_SUBMIT_FIELDS,
  submitOnError: false,
  submitOnSuccess: true,
  successCallback: null,
  translations: {},
  [TokenizedCardPaymentConfigName]: DefaultTokenizedCardPaymentConfig,
};
