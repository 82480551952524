import { IStyle } from "../../../../shared/model/config/IStyle";
import { IStyles } from "../../../../shared/model/config/IStyles";
import { StyleValidation } from "./StyleValidation";

export class StyleConfigAdaptor {
  static adaptStylesConfig(
    styles: IStyles | Record<string, string | IStyle>,
  ): IStyles {
    if (
      styles &&
      typeof styles === "object" &&
      "defaultStyles" in styles &&
      Object.keys(styles).length === 1 &&
      Object.keys(styles.defaultStyles).length === 0
    ) {
      return styles as IStyles;
    }

    const newStyles: IStyles = {};

    const isOldStyle = Object.values(styles).every(
      (value) => typeof value !== "object" || value === null,
    );

    if (isOldStyle) {
      newStyles.defaultStyles = StyleValidation.validateStyles(
        styles as Record<string, string>,
      );
    } else {
      const styleSections: (keyof IStyles)[] = [
        "defaultStyles",
        "cardNumber",
        "expirationDate",
        "securityCode",
        "notificationFrame",
        "controlFrame",
      ];

      const topLevelStyles = Object.entries(styles)
        .filter(
          ([key, value]) =>
            !styleSections.includes(key as keyof IStyles) &&
            (typeof value !== "object" || value === null),
        )
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      const mergedDefaultStyles = {
        ...topLevelStyles,
        ...((styles.defaultStyles as IStyle) || {}),
      };

      if (Object.keys(mergedDefaultStyles).length > 0) {
        newStyles.defaultStyles =
          StyleValidation.validateStyles(mergedDefaultStyles);
      }

      styleSections.forEach((section) => {
        if (section !== "defaultStyles" && styles[section]) {
          newStyles[section] = StyleValidation.validateStyles(
            styles[section] as IStyle,
          );
        }
      });
    }
    return newStyles;
  }
}
