import { Container } from "typedi";
import { GooglePaySdkProviderMock } from "../client/integrations/google-pay/google-pay-sdk-provider/GooglePaySdkProviderMock";
import { IGooglePaySdkProvider } from "../client/integrations/google-pay/google-pay-sdk-provider/IGooglePaySdkProvider";
import { ENVIRONMENT } from "../environments/environment";
import { CardinalProvider } from "../client/integrations/cardinal-commerce/CardinalProvider";
import { IHttpOptionsProvider } from "../application/core/services/st-transport/http-options-provider/IHttpOptionsProvider";
import { TestHttpOptionsProvider } from "../application/core/services/st-transport/http-options-provider/TestHttpOptionsProvider";
import { MockCardinalProvider } from "./mocks/MockCardinalProvider";

if (ENVIRONMENT.testEnvironment) {
  Container.set({ id: CardinalProvider, type: MockCardinalProvider });
  Container.set({ id: IGooglePaySdkProvider, type: GooglePaySdkProviderMock });
  Container.set({ id: IHttpOptionsProvider, type: TestHttpOptionsProvider });
}
