import { Service } from "typedi";
import { ValidationResult } from "joi";
import { CONFIG_SCHEMA } from "../storage/ConfigSchema";
import { IConfig } from "../../model/config/IConfig";

@Service()
export class ConfigValidator {
  validate(config: IConfig): ValidationResult {
    return CONFIG_SCHEMA.validate(config);
  }
}
