import { IApplePaySupportedNetworks } from "./IApplePaySupportedNetworks";

export const VERSION_ONE_NETWORKS: IApplePaySupportedNetworks[] = [
  "amex",
  "chinaUnionPay",
  "discover",
  "interac",
  "masterCard",
  "privateLabel",
  "visa",
];

export const VERSION_TWO_NETWORKS: IApplePaySupportedNetworks[] =
  VERSION_ONE_NETWORKS.concat(["jcb"]);

export const VERSION_FOUR_NETWORKS: IApplePaySupportedNetworks[] =
  VERSION_TWO_NETWORKS.concat([
    "cartesBancaires",
    "eftpos",
    "electron",
    "maestro",
    "vPay",
  ]);

export const VERSION_FIVE_NETWORKS: IApplePaySupportedNetworks[] =
  VERSION_FOUR_NETWORKS.concat(["elo", "mada"]);

export const VERSION_ELEVEN_NETWORKS: IApplePaySupportedNetworks[] =
  VERSION_FIVE_NETWORKS.concat(["girocard", "mir"]);

export const VERSION_THIRTEEN_NETWORKS: IApplePaySupportedNetworks[] =
  VERSION_ELEVEN_NETWORKS.concat(["dankort"]);

export const VERSION_FOURTEEN_NETWORKS: IApplePaySupportedNetworks[] =
  VERSION_THIRTEEN_NETWORKS.concat(["bancomat", "bancontact"]);
