import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IStartPaymentMethod } from "../../../application/core/services/payments/events/IStartPaymentMethod";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { PayPalPaymentMethodName } from "../constants/PayPalConstants";
import { IPayPalResolveParameter } from "../models/IPayPalResolveParameter";
import { IPayPalCallbackResolveAction } from "../models/IPayPalCallbackResolveAction";
import { IPayPalGatewayRequest } from "../models/IPayPalPayRequest";
import { type IPayPalTokenPublisher } from "./IPayPalTokenPublisher";

/**
 * The action to execute when the merchant developer calls `.resolve()` on the action
 * that's returned as part of the createOrder callback.
 */
export class CreateOrderResolver implements IPayPalCallbackResolveAction {
  constructor(
    private readonly messageBus: IMessageBus,
    private readonly tokenPublisher: IPayPalTokenPublisher,
    private readonly fallbackJwt: string,
  ) {}

  public resolve(param?: IPayPalResolveParameter) {
    this.messageBus.publish<IStartPaymentMethod<IPayPalGatewayRequest>>({
      type: PUBLIC_EVENTS.PAYPAL_CREATE_ORDER_REQUEST,
      data: {
        data: {
          jwt: param?.jwt ?? this.fallbackJwt,
        },
        name: PayPalPaymentMethodName,
      },
    });
  }
}
