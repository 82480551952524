import { JwtDecoder } from "../../../../../shared/services/jwt-decoder/JwtDecoder";
import { IPayPalGatewayRequest } from "../../../models/IPayPalPayRequest";
import { type IPayPalJwtListener } from "../../IPayPalJwtListener";
import { IMockedPayPalModalCallbacks } from "./IMockedPayPalModalCallbacks";

export class PayPalIFrameMock {
  private iframe: HTMLIFrameElement;
  private popupWindow: Window | null;
  private callbacks: IMockedPayPalModalCallbacks[];
  private windowCloseAction = () => this.onWindowClosed(true);

  constructor(
    private jwtDecoder: JwtDecoder,
    private paypalJwtListener: IPayPalJwtListener,
  ) {
    this.callbacks = [];
  }

  registerCallbacks(windowCallbacks: IMockedPayPalModalCallbacks) {
    this.callbacks.push(windowCallbacks);
  }

  openPaymentSheet() {
    const popupWidth = 400;
    const popupHeight = 600;

    const leftPosition = (window.innerWidth - popupWidth) / 2;
    const topPosition = (window.innerHeight - popupHeight) / 2;

    this.popupWindow = window.open(
      "",
      "_blank",
      `width=${popupWidth}, height=${popupHeight}, top=${topPosition}, left=${leftPosition}`,
    );

    if (this.popupWindow) {
      this.popupWindow.addEventListener("beforeunload", this.windowCloseAction);
      this.popupWindow.addEventListener("error", (errorEvent) =>
        this.onWindowClosed(false, errorEvent.error),
      );
      const iframe = this.popupWindow.document.createElement("iframe");
      const htmlContent = `
        <html>
          <head>         
          </head>
          <body>
            <div id="title">Awaiting order...</div>
            <br>
            <div id="container" style="display:none;text-align: center;">
              <label id="reject-shipping-msg" style="display:none; color:red">
                Test Store doesn't send to this location. Please use a different address.
              </label>
              <br>
              <button id="cancel">Cancel</button>
              <button id="error">Error</button>
              <button id="authorize">Authorize</button>              
              <select style="display:none;" id="address">
                  <option value="address1">Address 1</option>
                  <option value="address2">Address 2</option>
              </select>              
            </div>
          </body>
        </html>
      `;

      iframe.srcdoc = htmlContent;
      iframe.width = "100%";
      iframe.height = "100%";
      iframe.onload = () => {
        this.onWindowOpened();
      };
      this.popupWindow.document.body.appendChild(iframe);
      this.iframe = iframe;
    } else {
      console.error("Popup window couldn't be opened");
    }
  }

  onWindowOpened = () => {
    this.initModalEvents();
    for (const callback of this.callbacks) {
      if (callback && callback.onWindowOpened) {
        callback.onWindowOpened();
      }
    }
  };

  onWindowReady = () => {
    for (const callback of this.callbacks) {
      if (callback && callback.onWindowReady) {
        callback.onWindowReady();
      }
    }
  };

  onWindowClosed = (closedByUser: boolean, errorDetail?: Error) => {
    this.closeModal();
    for (const callback of this.callbacks) {
      if (callback && callback.onWindowClosed) {
        callback.onWindowClosed(closedByUser, errorDetail);
      }
    }
  };

  onAddressChanged = (addressData: {
    city: string;
    countryCode: string;
    postalCode: string;
    state: string;
  }) => {
    for (const callback of this.callbacks) {
      if (callback && callback.onAddressChanged) {
        callback.onAddressChanged(addressData);
      }
    }
  };

  onAuthorized = () => {
    this.closeModal();
    for (const callback of this.callbacks) {
      if (callback && callback.onAuthorized) {
        callback.onAuthorized();
      }
    }
  };

  activateModalEventsButtons = () => {
    const doc = this.iframe.contentWindow.document;
    doc.getElementById("container").style.display = "block";
    doc.getElementById("title").style.display = "none";
  };

  private initModalEvents() {
    const doc = this.iframe.contentWindow.document;
    if (!doc) {
      return;
    }

    this.paypalJwtListener
      .orderJwtAvailable()
      .subscribe(async (jwt: string) => {
        this.displayShippingAddressComponent(doc, jwt);
      });

    doc.getElementById("cancel").onclick = this.windowCloseAction;
    doc.getElementById("error").onclick = () =>
      this.onWindowClosed(false, new Error("Error"));
    doc.getElementById("authorize").onclick = this.onAuthorized;
  }

  private displayShippingAddressComponent(doc: Document, jwt: string) {
    const addressSelector = doc.getElementById("address");
    if (!addressSelector) {
      return;
    }
    const paypalAddressOverride: string = (
      this.jwtDecoder.decode<IPayPalGatewayRequest>(jwt).payload as any
    ).paypaladdressoverride;

    if (!paypalAddressOverride || paypalAddressOverride === "2") {
      return;
    } else if (paypalAddressOverride === "0") {
      addressSelector.style.display = "inline-block";
      this.handleAddressChange(doc);
    } else if (paypalAddressOverride === "1") {
      addressSelector.style.display = "inline-block";
      addressSelector.setAttribute("disabled", "disabled");
    }
  }

  private handleAddressChange(doc: Document) {
    const addresses = {
      address1: {
        city: "Bangor",
        countryCode: "GB",
        postalCode: "LL57 4BL",
        state: "",
      },
      address2: {
        city: "London",
        countryCode: "GB",
        postalCode: "EC3V 3DG",
        state: "",
      },
    };
    doc.getElementById("address").onchange = (data: any) => {
      const { value: selectedOption } = data.target;

      const addressData = addresses[selectedOption];

      if (!addressData) {
        console.warn(
          "Selected option does not have a corresponding address:",
          selectedOption,
        );
        return;
      }
      this.onAddressChanged(addressData);
    };

    /**
     * To maintain consistency with the real PayPal payment sheet,
     * which automatically triggers the "onShippingChange" function upon launch,
     * based on that similar call has been made here.
     */
    this.onAddressChanged(addresses["address1"]);
  }

  private closeModal() {
    if (this.popupWindow) {
      this.popupWindow.removeEventListener(
        "beforeunload",
        this.windowCloseAction,
      );
      this.popupWindow.close();
    }
  }

  showRejectShippingChangesMessage() {
    const doc = this.iframe.contentWindow.document;
    doc.getElementById("reject-shipping-msg").style.display = "inline-block";
  }
}
