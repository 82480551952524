import { PayPalNamespace, loadScript } from "@paypal/paypal-js";
import { IPayPalConfig } from "../models/IPayPalConfig";
import { IPayPalInjector } from "./IPayPalInjector";
import { PayPalConfigMapper } from "./PayPalConfigMapper";

/**
 * The class which uses the official PayPal SDK to inject the namespace into the
 * application. This should be used in the prod and sandbox environments.
 */
export class OfficialPayPalNamespaceInjector implements IPayPalInjector {
  async inject(
    config: IPayPalConfig,
    liveStatus: number,
  ): Promise<PayPalNamespace> {
    const paypal = await loadScript(
      PayPalConfigMapper.mapOwnConfigToPayPal(config, liveStatus),
    );

    return paypal;
  }
}
