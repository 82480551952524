import { JWK } from "jose";

export const VISA_PEM = `
-----BEGIN CERTIFICATE-----
MIIFDzCCA/egAwIBAgIUGia+bgG/HZCJvOJ2CDGqqMIyuQswDQYJKoZIhvcNAQEL
BQAwfTELMAkGA1UEBhMCVVMxDTALBgNVBAoTBFZJU0ExLzAtBgNVBAsTJlZpc2Eg
SW50ZXJuYXRpb25hbCBTZXJ2aWNlIEFzc29jaWF0aW9uMS4wLAYDVQQDEyVWaXNh
IEluZm9ybWF0aW9uIERlbGl2ZXJ5IEV4dGVybmFsIENBMB4XDTIwMTAwNzA4MDAx
MVoXDTIzMDEwNTA4MDAxMVowgYkxFDASBgNVBAcMC0Zvc3RlciBDaXR5MQswCQYD
VQQIDAJDQTELMAkGA1UEBhMCVVMxETAPBgNVBAoMCFZpc2EgSW5jMRgwFgYDVQQL
DA9PdXRib3VuZCBDbGllbnQxKjAoBgNVBAMMIWVuYy0xYmRhYjNjYy5zYnguZGln
aXRhbC52aXNhLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBALGT
yLrA3+8kJ54QZFPZrteFTjtwq7ot2+6wH9imHqG1EZlwNe29SV/Y4NAnfr4KZrsk
68QVEnPuG9PlD4EsBhaj1lKcdPBMaq8DwzrxaIwaLtsJcqvG3MtFLPgX3gs0hOZT
FXUl01113Darvqm9towggiQC6cbAK7G6DDl7C3nYdzOFzUOVxgLUX+2/vrk2OQ6/
Si2MlL2SgJ2NODCiTxDueGIiBTOQ0wITTprNPPY3THcflESCiYz1NvkaRxtbvCwh
tNvEUyKT7DW0Wp7kuUwGP85cQ4G9YSid+mG2RDRy4PT10wYXTjJVPdA0y5X3Gy02
vg+w4JYwGlgrr8yz2hsCAwEAAaOCAXgwggF0MAwGA1UdEwEB/wQCMAAwHwYDVR0j
BBgwFoAUGTpSZs0pH+P6yzR9FnYhAtpPuRgwZQYIKwYBBQUHAQEEWTBXMC4GCCsG
AQUFBzAChiJodHRwOi8vZW5yb2xsLnZpc2FjYS5jb20vdmljYTMuY2VyMCUGCCsG
AQUFBzABhhlodHRwOi8vb2NzcC52aXNhLmNvbS9vY3NwMDkGA1UdIAQyMDAwLgYF
Z4EDAgEwJTAjBggrBgEFBQcCARYXaHR0cDovL3d3dy52aXNhLmNvbS9wa2kwEwYD
VR0lBAwwCgYIKwYBBQUHAwIwXQYDVR0fBFYwVDAooCagJIYiaHR0cDovL0Vucm9s
bC52aXNhY2EuY29tL1ZJQ0EzLmNybDAooCagJIYiaHR0cDovL2NybC5pbm92LnZp
c2EubmV0L1ZJQ0EzLmNybDAdBgNVHQ4EFgQUNW/PkNTl6yw+1mthz/0FKsN8zjgw
DgYDVR0PAQH/BAQDAgeAMA0GCSqGSIb3DQEBCwUAA4IBAQAv4hzfp2+dkMFfpP8s
KWPrXzzMWuo+rXtjijrqgE2cPQO5wNo8639eCyvc7hH45qUV85AlfDp1T6QjL3ih
zywoooa0Tm1htJLPOGhSmaCO3NE4VMzeZkruVoG9rLAu9Vk4MygcDljL/EbMAYKE
K7YI0hP19HYD7M9NhXhvwE6LnyrITKPNrntnzn1Ave8lE7vJ1hTF68f8jOVmVvtp
rfGQ+gM8Et4pc1zoi4Jmz5J1YOSOY8KdgYtl0QTE6vyVNo8zkzggUqb0vqd4fR6o
JweYbZt2x8j75ze/mrDGacQIiRLSI5Em9UYYtW0Wyy+qUwzqgQng2Gxrr2yxShV3
0tgK
-----END CERTIFICATE-----`;

// JWK generated from PEM
export const VISA_SANDBOX_JWK: JWK = {
  kty: "RSA",
  n: "sZPIusDf7yQnnhBkU9mu14VOO3Crui3b7rAf2KYeobURmXA17b1JX9jg0Cd-vgpmuyTrxBUSc-4b0-UPgSwGFqPWUpx08ExqrwPDOvFojBou2wlyq8bcy0Us-BfeCzSE5lMVdSXTXXXcNqu-qb22jCCCJALpxsArsboMOXsLedh3M4XNQ5XGAtRf7b--uTY5Dr9KLYyUvZKAnY04MKJPEO54YiIFM5DTAhNOms089jdMdx-URIKJjPU2-RpHG1u8LCG028RTIpPsNbRanuS5TAY_zlxDgb1hKJ36YbZENHLg9PXTBhdOMlU90DTLlfcbLTa-D7DgljAaWCuvzLPaGw",
  e: "AQAB",
};
