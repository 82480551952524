import Container from "typedi";
import {
  PayPalButtonsComponentOptions,
  OnClickActions,
  CreateOrderRequestBody,
  OnApproveData,
  OnShippingChangeData,
  OnShippingChangeActions,
  OnCancelledActions,
  OnApproveActions,
} from "@paypal/paypal-js";
import { PayPalIFrameMock } from "../mocks/payment-modal/PayPalIFrameMock";
import { JwtDecoder } from "../../../../shared/services/jwt-decoder/JwtDecoder";
import { PAYAPAL_JWT_LISTENER_TOKEN } from "../../../../client/dependency-injection/InjectionTokens";

export class PayPalMockButtonClickHandler {
  private orderId: string;
  private paypalMock: PayPalIFrameMock;

  constructor(orderId: string) {
    this.orderId = orderId;
  }

  private createOrderResponseBody(intent: string, value?: string) {
    return {
      id: "",
      status: "CREATED",
      links: [],
      intent,
      purchase_units: value
        ? [
            {
              amount: {
                breakdown: {},
                value,
              },
            },
          ]
        : [],
    };
  }

  private handleAsyncOperation(
    operation: () => Promise<any>,
    onSuccess: () => void,
    onFailure: (error: any) => void,
  ) {
    operation().then(onSuccess).catch(onFailure);
  }

  private onApprove(options: PayPalButtonsComponentOptions) {
    const onApproveData: OnApproveData = {
      facilitatorAccessToken: "",
      orderID: this.orderId,
    };
    const onApproveActions: OnApproveActions = {
      order: {
        capture: async () =>
          // @ts-ignore
          this.createOrderResponseBody("CAPTURE", "10.00"),
        authorize: async () =>
          // @ts-ignore
          this.createOrderResponseBody("AUTHORIZE"),
      },
    };
    this.handleAsyncOperation(
      () => onApproveActions.order.authorize(),
      () => options.onApprove(onApproveData, onApproveActions),
      (error) => this.onError(options, error),
    );
  }
  private onShippingChange(
    options: PayPalButtonsComponentOptions,
    addressData: any,
  ) {
    const onShippingChangeData: OnShippingChangeData = {
      orderID: this.orderId,
      shipping_address: addressData,
      forceRestAPI: false,
    };

    const onShippingChangeActions: OnShippingChangeActions = {
      resolve: () => Promise.resolve(),
      reject: async () => {
        this.paypalMock.showRejectShippingChangesMessage();
      },
      order: { patch: () => Promise.resolve() },
    };

    options.onShippingChange(onShippingChangeData, onShippingChangeActions);
  }

  private onCancel(options: PayPalButtonsComponentOptions) {
    const onCancelledActions: OnCancelledActions = { redirect: () => {} };
    options.onCancel({}, onCancelledActions);
  }

  private onError(options: PayPalButtonsComponentOptions, error: Error) {
    options.onError({ errorDetail: error });
    console.error("PayPal Error:", error);
  }

  private onClick(
    options: PayPalButtonsComponentOptions,
    actions: OnClickActions,
  ) {
    if (typeof options.onClick === "function") {
      options.onClick({ fundingSource: options?.fundingSource }, actions);
    }
  }

  private createOrder(
    options: PayPalButtonsComponentOptions,
    paypalMock: PayPalIFrameMock,
  ) {
    this.handleAsyncOperation(
      () =>
        options.createOrder(
          { paymentSource: options?.fundingSource },
          {
            order: {
              create: async (options: CreateOrderRequestBody) =>
                (this.orderId = "orderId"),
            },
          },
        ),
      () => paypalMock.activateModalEventsButtons(),
      (error) => console.error("Error creating order:", error),
    );
  }

  private onWindowEventHandling(
    options: PayPalButtonsComponentOptions,
    paypalMock: PayPalIFrameMock,
  ) {
    paypalMock.registerCallbacks({
      onWindowOpened: () => this.createOrder(options, paypalMock),
      onWindowReady: () => console.log("onWindowReady callback raised"),
      onWindowClosed: (closedByUser: boolean, errorDetail?: Error) => {
        if (closedByUser) {
          this.onCancel(options);
        } else if (errorDetail) {
          this.onError(options, errorDetail);
        }
      },
      onAddressChanged: (addressData: any) =>
        this.onShippingChange(options, addressData),
      onAuthorized: () => this.onApprove(options),
    });
  }

  public handleButtonClick(
    options: PayPalButtonsComponentOptions,
    event: Event,
  ) {
    event.preventDefault();
    const clientOnClickActions: OnClickActions = {
      reject: () => Promise.resolve(),
      resolve: async () => {
        this.paypalMock = new PayPalIFrameMock(
          Container.get(JwtDecoder),
          Container.get(PAYAPAL_JWT_LISTENER_TOKEN),
        );
        this.onWindowEventHandling(options, this.paypalMock);
        this.paypalMock.openPaymentSheet();
      },
    };

    this.onClick(options, clientOnClickActions);
  }
}
