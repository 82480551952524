import { Service } from "typedi";
import { Locale } from "./Locale";
import { ITranslator } from "./ITranslator";
import { ITranslationProvider } from "./ITranslationProvider";

@Service()
export class Translator implements ITranslator {
  // The reference to grab our translations from
  private translationLookup: object;
  constructor(private translationProvider: ITranslationProvider) {}

  init(): void {
    // Set default language
    this.translationLookup = this.translationProvider.getResources()["en_GB"];
  }

  changeLanguage(language: Locale = "en_GB"): void {
    this.translationLookup = this.translationProvider.getResources()[language];
  }

  translate(text: string): string {
    /** return without translating if text is undefined or translator is not available
  return without treanslation if the text is not found in translation list*/
    if (!text || !this.translationLookup) return text;
    return this.translationLookup["translation"][text] ?? text;
  }
}
