import { Service } from "typedi";
import { IMessageSubscriber } from "../../../shared/services/message-bus/interfaces/IMessageSubscriber";
import { MESSAGE_SUBSCRIBER_TOKEN } from "../../../shared/dependency-injection/InjectionTokens";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IMessageBusEvent } from "../../../application/core/models/IMessageBusEvent";
import { IConfig } from "../../../shared/model/config/IConfig";
import { IFrameQueryingService } from "../../../shared/services/message-bus/interfaces/IFrameQueryingService";
import { ConfigProvider } from "../../../shared/services/config-provider/ConfigProvider";
import { ApplePayClient } from "./ApplePayClient";

@Service({ id: MESSAGE_SUBSCRIBER_TOKEN, multiple: true })
export class ApplePayClientInitializer implements IMessageSubscriber {
  constructor(
    private applePayClient: ApplePayClient,
    private frameQueryingService: IFrameQueryingService,
    private configProvider: ConfigProvider,
  ) {}

  register(): void {
    this.frameQueryingService.whenReceive(
      PUBLIC_EVENTS.APPLE_PAY_INIT_CLIENT,
      (event: IMessageBusEvent<IConfig>) =>
        // By using the provider, we get function/callbacks in a non-serialized way
        this.applePayClient.init(this.configProvider.getConfig()),
    );
  }
}
