import { Inject, Service } from "typedi";
import { Observable } from "rxjs";
import { map, mapTo, switchMap, takeUntil, tap } from "rxjs/operators";
import { DomMethods } from "../../../application/core/shared/dom-methods/DomMethods";
import { IAPMItemConfig } from "../models/IAPMItemConfig";
import { IAPMConfig } from "../models/IAPMConfig";
import { APMConfigResolver } from "../services/apm-config-resolver/APMConfigResolver";
import { IStartPaymentMethod } from "../../../application/core/services/payments/events/IStartPaymentMethod";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { Debug } from "../../../shared/Debug";
import { APM_PAYMENT_METHOD_NAME } from "../models/IAPMPaymentMethod";
import { APMName } from "../models/APMName";
import { ofType } from "../../../shared/services/message-bus/operators/ofType";
import { IMessageBusEvent } from "../../../application/core/models/IMessageBusEvent";
import "./APMClient.scss";
import { APMFilterService } from "../services/apm-filter-service/APMFilterService";
import { ConfigProvider } from "../../../shared/services/config-provider/ConfigProvider";
import { untilDestroy } from "../../../shared/services/message-bus/operators/untilDestroy";
import { EventScope } from "../../../application/core/models/constants/EventScope";
import { IConfig } from "../../../shared/model/config/IConfig";
import { type IInternalsMonitor } from "../../../application/core/services/monitoring/IInternalsMonitor";

@Service()
export class APMClient {
  private apmIcons: Record<APMName, string> = {
    [APMName.ACCOUNT2ACCOUNT]: "",
    [APMName.ALIPAY]: require("./images/alipay.svg"),
    [APMName.BANCONTACT]: require("./images/bancontact.svg"),
    [APMName.BITPAY]: require("./images/bitpay.svg"),
    [APMName.EPS]: require("./images/eps.svg"),
    [APMName.GIROPAY]: require("./images/giropay.svg"),
    [APMName.IDEAL]: require("./images/ideal.svg"),
    [APMName.MULTIBANCO]: require("./images/multibanco.svg"),
    [APMName.MYBANK]: require("./images/mybank.svg"),
    [APMName.PAYU]: require("./images/payu.svg"),
    [APMName.POSTFINANCE]: require("./images/postfinance.svg"),
    [APMName.PRZELEWY24]: require("./images/przelewy24.svg"),
    [APMName.REDPAGOS]: require("./images/redpagos.svg"),
    [APMName.SAFETYPAY]: require("./images/safetypay.svg"),
    [APMName.SEPADD]: require("./images/sepadd.svg"),
    [APMName.SOFORT]: require("./images/sofort.svg"),
    [APMName.TRUSTLY]: require("./images/trustly.svg"),
    [APMName.UNIONPAY]: require("./images/unionpay.svg"),
    [APMName.WECHATPAY]: require("./images/wechatpay.svg"),
    [APMName.ZIP]: require("./images/zip.svg"),
  };
  private destroy$: Observable<void>;

  constructor(
    private apmConfigResolver: APMConfigResolver,
    private messageBus: IMessageBus,
    private apmFilterService: APMFilterService,
    private configProvider: ConfigProvider,
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
  ) {}

  init(config: IAPMConfig): Observable<undefined> {
    this.messageBus
      .pipe(
        ofType(PUBLIC_EVENTS.UPDATE_JWT),
        map((event) => event.data.newJwt),
        switchMap((updatedJwt) => this.filter(config, updatedJwt)),
        untilDestroy(this.messageBus),
      )
      .subscribe((list: IAPMItemConfig[]) => this.insertAPMButtons(list));

    return this.filter(config, this.configProvider.getConfig().jwt).pipe(
      tap((list: IAPMItemConfig[]) => this.insertAPMButtons(list)),
      mapTo(undefined),
    );
  }

  private filter(
    config: IAPMConfig,
    jwt: string,
  ): Observable<IAPMItemConfig[]> {
    return this.apmConfigResolver.resolve(config).pipe(
      switchMap((normalizedConfig) =>
        this.apmFilterService.filter(
          normalizedConfig.apmList as IAPMItemConfig[],
          jwt,
        ),
      ),
      untilDestroy(this.messageBus),
    ) as Observable<IAPMItemConfig[]>;
  }

  private insertAPMButtons(itemList: IAPMItemConfig[]): void {
    this.clearExistingButtons();
    itemList.forEach((item: IAPMItemConfig) => {
      this.insertAPMButton(item);
    });
  }

  private clearExistingButtons(): void {
    document
      .querySelectorAll("div.st-apm-button")
      .forEach((element) => element.remove());
  }

  private insertAPMButton(apmItemConfig: IAPMItemConfig): void {
    try {
      DomMethods.appendChildStrictIntoDOM(
        apmItemConfig.placement,
        this.createButtonForApmItem(apmItemConfig),
      );
    } catch (error: unknown) {
      //@ts-ignore
      //Ignored, as the exact error type cannot be determined at this stage.
      this.internalMonitor.recordIssue(error, apmItemConfig);
    }
  }

  private createButtonForApmItem(apmItemConfig: IAPMItemConfig): HTMLElement {
    const button = DomMethods.createHtmlElement(
      { class: "st-apm-button" },
      "div",
    );
    if (this.apmIcons[apmItemConfig.name]) {
      button.innerHTML = `<img src="${
        this.apmIcons[apmItemConfig.name]
      }" alt="${apmItemConfig.name}" id="ST-APM-${
        apmItemConfig.name
      }" class="st-apm-button__img">`;
    } else {
      button.classList.add("st-apm-button--withButton");
      button.innerHTML = `<button class="st-apm-button__button" id="ST-APM-${apmItemConfig.name}" style="min-width: ${apmItemConfig.button.width}; height: ${apmItemConfig.button.height}; background-color: ${apmItemConfig.button.backgroundColor}; color: ${apmItemConfig.button.textColor};" type="button"><span>${apmItemConfig.button.text}</span></button>`;
    }
    button.addEventListener("click", (event) =>
      this.onAPMButtonClick(event, apmItemConfig),
    );

    return button;
  }

  private onAPMButtonClick(event: Event, config: IAPMItemConfig) {
    event.preventDefault();
    Debug.log(
      `Payment method initialized: ${config.name}. Payment button clicked`,
    );

    const data: any = {
      /** @deprecated New clients should use ‘name’ instead, which is more consistent with other library callbacks. Note that the casing of the value will change */
      paymentOption: config.name,
      // TODO: Review this, as instead of APM, it will be BITPAY etc
      name: config.name,
    };

    if (
      this.isPaymentPrecheckEnabled(
        this.configProvider.getConfig(),
        config.name,
      )
    ) {
      data.paymentStart = () => this.processPayment(config);
    }

    this.messageBus.publish(
      {
        type: PUBLIC_EVENTS.PAYMENT_METHOD_PRE_CHECK,
        data,
      },
      EventScope.EXPOSED,
    );

    if (!data.paymentStart) {
      this.processPayment(config);
    }
  }

  private processPayment(config: IAPMItemConfig): void {
    const paymentFailedEvent = this.messageBus.pipe(
      ofType(PUBLIC_EVENTS.PAYMENT_METHOD_FAILED),
    );

    this.messageBus.publish<IStartPaymentMethod<IAPMItemConfig>>({
      type: PUBLIC_EVENTS.START_PAYMENT_METHOD,
      data: {
        data: config,
        name: APM_PAYMENT_METHOD_NAME,
      },
    });

    this.messageBus
      .pipe(
        ofType(PUBLIC_EVENTS.APM_REDIRECT),
        untilDestroy(this.messageBus),
        takeUntil(paymentFailedEvent),
      )
      .subscribe((event: IMessageBusEvent<string>) => {
        DomMethods.redirect(event.data);
      });
  }

  /**
   * A check to determine if the merchant has specified that
   * Card Payments should be configured to not auto-start.
   *
   * Auto-start gets disabled by adding ["CARD"] to disabledAutoPaymentStart.
   *
   * @param config The config.
   *
   * @returns
   *  True if the merchant wants control over the payment
   * start action, rather than having the code execute it
   * directly
   */
  private isPaymentPrecheckEnabled(
    config: IConfig,
    paymentName: string,
  ): boolean {
    return (config.disabledAutoPaymentStart ?? [])
      .map((paymentMethodName) => paymentMethodName.toLowerCase())
      .includes(paymentName.toLowerCase());
  }
}
