import { Service } from "typedi";
import { ApplePayInitError } from "../models/errors/ApplePayInitError";
import { IApplePayAvailabilityResult } from "./IApplePayAvailabilityResult";
import { IApplePaySessionAvailabilityChecker } from "./IApplePaySessionAvailabilityChecker";
import { IApplePayNamespace } from "./models/IApplePayNamespace";

@Service()
export class ApplePaySessionAvailabilityChecker
  implements IApplePaySessionAvailabilityChecker
{
  constructor(private applePayNamespace: IApplePayNamespace) {}

  isAvailable(): IApplePayAvailabilityResult {
    const notAvailable = (reason: string): IApplePayAvailabilityResult => {
      console.warn(`ApplePay not available: ${reason}`);
      return {
        isAvailable: false,
        error: new ApplePayInitError(`ApplePay not available: ${reason}`),
      };
    };

    if (!this.applePayNamespace.isApplePaySessionAvailable()) {
      return notAvailable("Works only on Safari");
    }

    if (!this.applePayNamespace.canMakePayments()) {
      return notAvailable(
        "Your device does not support making payments with Apple Pay",
      );
    }

    return { isAvailable: true };
  }
}
