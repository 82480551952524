import { IStyle } from "../../../../shared/model/config/IStyle";

export class StyleValidation {
  static validateStyles(styles: IStyle): IStyle {
    const validatedStyles: IStyle = {};

    for (const [key, value] of Object.entries(styles)) {
      switch (key) {
        case "font-weight-label":
          if (this.isValidFontWeight(value as string)) {
            validatedStyles[key] = value;
          } else {
            console.warn(
              `Invalid font-weight value: "${value}". This setting will be ignored.`,
            );
          }
          break;
        default:
          validatedStyles[key] = value;
      }
    }

    return validatedStyles;
  }

  static isValidFontWeight(value: string): boolean {
    const validValues = [
      "normal",
      "bold",
      "bolder",
      "lighter",
      "initial",
      "inherit",
    ];
    const numericValue = parseInt(value, 10);

    return (
      validValues.includes(value) ||
      (numericValue >= 100 && numericValue <= 900)
    );
  }
}
