import { JWK } from "jose";

export const VISA_PEM = `
-----BEGIN CERTIFICATE-----
MIIFGDCCBACgAwIBAgIUN+C0JL7aLaNl6at/Okrll/EA/34wDQYJKoZIhvcNAQEL
BQAwfTELMAkGA1UEBhMCVVMxDTALBgNVBAoTBFZJU0ExLzAtBgNVBAsTJlZpc2Eg
SW50ZXJuYXRpb25hbCBTZXJ2aWNlIEFzc29jaWF0aW9uMS4wLAYDVQQDEyVWaXNh
IEluZm9ybWF0aW9uIERlbGl2ZXJ5IEV4dGVybmFsIENBMB4XDTIwMTAwNzA4MDEz
NVoXDTIzMDEwNTA4MDEzNVowgZIxFDASBgNVBAcMC0Zvc3RlciBDaXR5MRMwEQYD
VQQIDApDYWxpZm9ybmlhMQswCQYDVQQGEwJVUzERMA8GA1UECgwIVmlzYSBJbmMx
GDAWBgNVBAsMD091dGJvdW5kIENsaWVudDErMCkGA1UEAwwiZW5jLThiNmI1ZTZj
LnByb2QuZGlnaXRhbC52aXNhLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCC
AQoCggEBAJD7o8FSY3ryP6HmcGaANlo7feAxXDL0Qmm/IkYhBqxnylrTQsEGJMpc
rkaAeteXGJtgVW76Ch/GMr9Ggt3cGoelJjnSbM5i9KVf3G8f7V81oGIN7GDbKdLA
cVBXENQOg1W8jscSW5p8JRxhOX5Lsya/qqrpd0bMLAhvrz64f/vtv0UoqWvZVa/Y
L3yOAGxQAS06inraNK1j2yASnYXBkOyTD8c6vNt/Sj1GX0sjbngHHS/shwA4ty9l
Us3Re7Uu7It7nvyBaFw6W4ILBcbxLe/VJQQzgbGoHP5rPf2bGJSWigk/GY8IWpDK
P/mXXPe5rnVY9Y89vy+ZeJf84mxea4sCAwEAAaOCAXgwggF0MAwGA1UdEwEB/wQC
MAAwHwYDVR0jBBgwFoAUGTpSZs0pH+P6yzR9FnYhAtpPuRgwZQYIKwYBBQUHAQEE
WTBXMC4GCCsGAQUFBzAChiJodHRwOi8vZW5yb2xsLnZpc2FjYS5jb20vdmljYTMu
Y2VyMCUGCCsGAQUFBzABhhlodHRwOi8vb2NzcC52aXNhLmNvbS9vY3NwMDkGA1Ud
IAQyMDAwLgYFZ4EDAgEwJTAjBggrBgEFBQcCARYXaHR0cDovL3d3dy52aXNhLmNv
bS9wa2kwEwYDVR0lBAwwCgYIKwYBBQUHAwIwXQYDVR0fBFYwVDAooCagJIYiaHR0
cDovL0Vucm9sbC52aXNhY2EuY29tL1ZJQ0EzLmNybDAooCagJIYiaHR0cDovL2Ny
bC5pbm92LnZpc2EubmV0L1ZJQ0EzLmNybDAdBgNVHQ4EFgQUNy9zr50lW0dvEBO/
3BZOrxcKh8MwDgYDVR0PAQH/BAQDAgeAMA0GCSqGSIb3DQEBCwUAA4IBAQA5kL7B
kzbB3+z/RSBlQVi+v3NSnKscrsXnBi4rJ5uYTnPwkaRQen/DXNHKcj22/buukgSj
x9osPEC/d+SfQeLojXgtG/UNvUUOl5ILdZLjfBhy3YyJ7xZuRjWAJDZWw0bmV3qe
MA1ISYEbIo/zk9CoiBPxcmZG92TiLywOtvhRLBigevLuSYebglqmhOZKU7/Sl29U
yH23qUhnkK/f/7y5kAcatOAb3wN4fOnsea04u72MdHaeWzTAw2QR69N3mrX4jHje
Eii2DctD5Lri384amkWdKnVypWi+qCQYo2mCFDuHPdWn1fR2WpquwJ9iijGYgJll
h+G6G5aYJljD07rV
-----END CERTIFICATE-----`;

// JWK generated from PEM
export const VISA_PROD_JWK: JWK = {
  kty: "RSA",
  kid: "enc-8b6b5e6c.prod.digital.visa.com",
  x5t: "h3xkFdd7s3FEk2vmPyK_SHcpUGs",
  n: "kPujwVJjevI_oeZwZoA2Wjt94DFcMvRCab8iRiEGrGfKWtNCwQYkylyuRoB615cYm2BVbvoKH8Yyv0aC3dwah6UmOdJszmL0pV_cbx_tXzWgYg3sYNsp0sBxUFcQ1A6DVbyOxxJbmnwlHGE5fkuzJr-qqul3RswsCG-vPrh_--2_RSipa9lVr9gvfI4AbFABLTqKeto0rWPbIBKdhcGQ7JMPxzq8239KPUZfSyNueAcdL-yHADi3L2VSzdF7tS7si3ue_IFoXDpbggsFxvEt79UlBDOBsagc_ms9_ZsYlJaKCT8ZjwhakMo_-Zdc97mudVj1jz2_L5l4l_zibF5riw",
  e: "AQAB",
};
