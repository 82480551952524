export enum APMCurrencyIso {
  USD = "USD",
  CAD = "CAD",
  EUR = "EUR",
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ARS = "ARS",
  AUD = "AUD",
  AZN = "AZN",
  BAM = "BAM",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CDF = "CDF",
  CHF = "CHF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EEK = "EEK",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GNF = "GNF",
  GTQ = "GTQ",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KHR = "KHR",
  KMF = "KMF",
  KRW = "KRW",
  KWD = "KWD",
  KZT = "KZT",
  LBP = "LBP",
  LKR = "LKR",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MOP = "MOP",
  MUR = "MUR",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SOS = "SOS",
  SYP = "SYP",
  THB = "THB",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  XAF = "XAF",
  XOF = "XOF",
  YER = "YER",
  ZAR = "ZAR",
  ZMK = "ZMK",
  ZWL = "ZWL",
}
