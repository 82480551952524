import { Service } from "typedi";
import { ConfigService } from "../../../../shared/services/config-service/ConfigService";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { IUpdateJwt } from "../../models/IUpdateJwt";
import { IMessageBus } from "../../shared/message-bus/IMessageBus";
import { ITranslator } from "../../shared/translator/ITranslator";

@Service()
export class RequestJwtUpdateHandler {
  constructor(
    private configService: ConfigService,
    private messageBus: IMessageBus,
    private translation: ITranslator,
  ) {}

  public request(jwt: string): void {
    if (jwt) {
      this.configService.updateProp("jwt", jwt);
      this.messageBus.publish<IUpdateJwt>({
        type: PUBLIC_EVENTS.UPDATE_JWT,
        data: { newJwt: jwt, timestamp: Date.now() },
      });
    } else {
      throw Error(this.translation.translate("Jwt has not been specified"));
    }
  }
}
