import { IApplePaySession } from "../../models/IApplePaySession";
import { IApplePayShippingMethodCallbackActions } from "../../models/IApplePayShippingMethodCallbackActions";
import { RequestJwtUpdateHandler } from "../../../../../application/core/services/st-codec/RequestJwtUpdateHandler";
import { APPLE_PAY_STATUS } from "../session/models/ApplePayStatus";
import { IJwtEnhancedApplePayShippingMethodUpdate } from "../../models/IJwtEnhancedApplePayShippingMethodUpdate";

export class ApplePayShippingMethodResolver
  implements IApplePayShippingMethodCallbackActions
{
  constructor(
    private readonly session: IApplePaySession,
    private readonly versionNumber: number,
    private readonly jwtRequestHandler: RequestJwtUpdateHandler,
  ) {}

  /**
   * A function to expose to the merchant. They will call resolve, and we'll
   * complete the shipping method selection for ApplePay.
   *
   * @param param The update data to provide to ApplePay.
   */
  public resolve(param: IJwtEnhancedApplePayShippingMethodUpdate): void {
    if (this.versionNumber <= 2) {
      this.session.completeShippingMethodSelection(
        APPLE_PAY_STATUS.STATUS_SUCCESS,
        param.newTotal,
        param.newLineItems,
      );
    } else {
      this.session.completeShippingMethodSelection(param);
    }
    if (param?.jwt) {
      //If they provide the JWT key, allow them to call updateJWT without needing a reference to the ST object.
      this.jwtRequestHandler.request(param.jwt);
    }
  }
}
