import { IApplePaySessionConstructor } from "./IApplePaySessionConstructor";

const applePaySession: IApplePaySessionConstructor | undefined = (
  window as unknown as IWindow
).ApplePaySession;
/* istanbul ignore file */
export const APPLE_PAY_STATUS = {
  STATUS_SUCCESS: applePaySession ? applePaySession.STATUS_SUCCESS : undefined,
  STATUS_FAILURE: applePaySession ? applePaySession.STATUS_FAILURE : undefined,
  STATUS_INVALID_BILLING_POSTAL_ADDRESS: applePaySession
    ? applePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS
    : undefined,
  STATUS_INVALID_SHIPPING_POSTAL_ADDRESS: applePaySession
    ? applePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS
    : undefined,
  STATUS_INVALID_SHIPPING_CONTACT: applePaySession
    ? applePaySession.STATUS_INVALID_SHIPPING_CONTACT
    : undefined,
  STATUS_PIN_INCORRECT: applePaySession
    ? applePaySession.STATUS_PIN_INCORRECT
    : undefined,
  STATUS_PIN_LOCKOUT: applePaySession
    ? applePaySession.STATUS_PIN_LOCKOUT
    : undefined,
  STATUS_PIN_REQUIRED: applePaySession
    ? applePaySession.STATUS_PIN_REQUIRED
    : undefined,
};
