import { forkJoin, from, Observable, of, switchMap, throwError } from "rxjs";
import { catchError, filter, map, mapTo } from "rxjs/operators";
import { InjectMany, Service } from "typedi";
import { SRC_PROVIDER_TOKEN } from "../../../client/dependency-injection/InjectionTokens";
import { forkJoinInOrder } from "../../../shared/rxjs/forkJoinInOrder";

import { RequestTimeoutError } from "../../../application/core/services/monitoring/sentry/error/RequestTimeoutError";
import {
  ICheckoutData,
  ICheckoutResponse,
  ICompleteIdValidationResponse,
  IConsumerIdentity,
  IIdentityLookupResponse,
  IInitiateIdentityValidationResponse,
  IIsRecognizedResponse,
  ISrc,
  ISrcInitData,
} from "./ISrc";
import { SrcName } from "./SrcName";
import { ISrcProvider } from "./ISrcProvider";
import { IIdentityLookupResult } from "./interfaces/IIdentityLookupResult";
import { IAggregatedProfiles } from "./interfaces/IAggregatedProfiles";
import { CardAggregator } from "./CardAggregator";

@Service()
export class SrcAggregate {
  private srcs: Map<SrcName, Observable<ISrc>> = new Map();

  constructor(
    @InjectMany(SRC_PROVIDER_TOKEN) private srcProviders: ISrcProvider[],
    private cardAggregator: CardAggregator,
  ) {}

  init(initData: ISrcInitData | Partial<ISrcInitData>): Observable<void> {
    this.srcProviders.forEach((srcProvider) => {
      this.srcs.set(srcProvider.getSrcName(), srcProvider.getSrc());
    });
    return this.forkJoinSrcs((src) => src.init(initData as ISrcInitData)).pipe(
      mapTo(undefined),
    );
  }

  isRecognized(): Observable<IIsRecognizedResponse> {
    return this.forkJoinSrcs((src) => src.isRecognized()).pipe(
      map((result) =>
        Object.values(result)
          .filter((value) => !!value)
          .reduce(
            (acc, next) => ({
              recognized: acc.recognized || next.recognized,
              idTokens: [...acc.idTokens, ...(next.idTokens || [])],
            }),
            { recognized: false, idTokens: [] },
          ),
      ),
    );
  }

  getSrcProfile(idTokens: string[]): Observable<IAggregatedProfiles> {
    return this.forkJoinSrcs((src) => src.getSrcProfile(idTokens)).pipe(
      map((result) => ({
        srcProfiles: result,
        aggregatedCards: this.cardAggregator.aggregate(result),
      })),
    );
  }

  identityLookup(
    consumerIdentity: IConsumerIdentity,
  ): Observable<IIdentityLookupResult> {
    const identityLookupResponses: Observable<IIdentityLookupResponse>[] =
      Array.from(this.srcs.values()).map((src$) =>
        src$
          .pipe(
            switchMap((src) => {
              return from(src.identityLookup(consumerIdentity));
            }),
          )
          .pipe(
            // TODO check if all errors can be ignored here
            catchError((error) => {
              if (
                (error.error && error.error.reason === "ACCT_INACCESSIBLE") ||
                error.toString().includes("user account has been locked") ||
                error instanceof RequestTimeoutError
              ) {
                return of(null);
              }
              return throwError(() => error);
            }),
          ),
      );
    return forkJoinInOrder<IIdentityLookupResponse>(
      identityLookupResponses,
    ).pipe(
      map((responses) => {
        const srcsWithConsumerPresent = responses.filter(
          (response) => response?.consumerPresent === true,
        );
        const srcNames = srcsWithConsumerPresent.map(({ srcName }) => srcName);

        return { consumerPresent: !!srcsWithConsumerPresent.length, srcNames };
      }),
    );
  }

  initiateIdentityValidation(
    srcName: SrcName,
    identityType?: string,
  ): Observable<IInitiateIdentityValidationResponse> {
    return this.srcs
      .get(srcName)
      .pipe(
        switchMap((src) => from(src.initiateIdentityValidation(identityType))),
      );
  }

  completeIdentityValidation(
    srcName: SrcName,
    validationData: string,
  ): Observable<ICompleteIdValidationResponse> {
    return this.srcs
      .get(srcName)
      .pipe(
        switchMap((src) =>
          from(src.completeIdentityValidation(validationData)),
        ),
      );
  }

  checkout(
    srcName: SrcName,
    data: ICheckoutData,
  ): Observable<ICheckoutResponse> {
    return this.srcs
      .get(srcName)
      .pipe(switchMap((src) => from(src.checkout(data))));
  }

  unbindAppInstance(): Observable<undefined> {
    return this.forkJoinSrcs((src) => src.unbindAppInstance()).pipe(
      mapTo(undefined),
    );
  }

  private forkJoinSrcs<T>(
    callback: (src: ISrc) => Promise<T>,
  ): Observable<Partial<Record<SrcName, T>>> {
    const sources: Partial<Record<SrcName, Observable<T>>> = {};

    this.srcs.forEach((src$, srcName) => {
      sources[srcName] = src$.pipe(
        filter((src) => !!src),
        switchMap((src) => from(callback(src))),
        catchError((error) => of(null)),
      );
    });

    return forkJoin(sources);
  }
}
