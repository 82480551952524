import {
  PayPalHostedFieldsComponentOptions,
  PayPalMarksComponentOptions,
  PayPalMessagesComponentOptions,
  PayPalMessagesComponent,
  PayPalMarksComponent,
  version,
  PayPalScriptOptions,
  PayPalNamespace,
} from "@paypal/paypal-js";
import { IPayPalConfig } from "../../models/IPayPalConfig";
import { PayPalConfigMapper } from "../PayPalConfigMapper";
import { IPayPalInjector } from "../IPayPalInjector";

import { PayPalFundingMock } from "./PayPalFundingMock";
import { PayPalMockedButtonComponent } from "./PayPalMockedButtonComponent";

/**
 * The class which uses the mocked PayPal SDK to inject the namespace into the
 * application. This should be used in the mocked environment.
 */
export class MockedPayPalNamespaceInjector extends IPayPalInjector {
  private orderId: string = "";

  async inject(
    config: IPayPalConfig,
    liveStatus: number,
  ): Promise<PayPalNamespace | null> {
    return this.loadScript(
      PayPalConfigMapper.mapOwnConfigToPayPal(config, liveStatus),
    );
  }
  private loadScript(paypalScriptConfig: PayPalScriptOptions): PayPalNamespace {
    const buttonComponent = new PayPalMockedButtonComponent(this.orderId);
    const fundingMock = new PayPalFundingMock();
    return {
      Buttons: buttonComponent.mockButtons.bind(buttonComponent),
      Marks: (options?: PayPalMarksComponentOptions) => this.mockMarks(options),
      Messages: (options?: PayPalMessagesComponentOptions) =>
        this.mockMessages(options),
      HostedFields: this.mockHostedFields(),
      getFundingSources: fundingMock.mockGetFundingSources.bind(fundingMock),
      isFundingEligible: fundingMock.mockIsFundingEligible.bind(fundingMock),
      rememberFunding: fundingMock.mockRememberFunding.bind(fundingMock),
      FUNDING: fundingMock.mockFunding.bind(fundingMock),
      version: version,
    };
  }

  private mockMarks(
    options?: PayPalMarksComponentOptions,
  ): PayPalMarksComponent {
    return {
      isEligible: () => true,
      render: async (container: HTMLElement | string) => {
        /* TODO: implementation for render method */
      },
    };
  }

  private mockMessages(
    options?: PayPalMessagesComponentOptions,
  ): PayPalMessagesComponent {
    return {
      render: async (container: HTMLElement | string) => {
        /* TODO: implementation for render method */
      },
    };
  }

  private mockHostedFields() {
    return {
      isEligible: () => true,
      render: async (options: PayPalHostedFieldsComponentOptions) => {
        return undefined;
      },
    };
  }
}
