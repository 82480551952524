import { Observable, OperatorFunction, throwError, timeout } from "rxjs";
import { Service } from "typedi";
import { ENVIRONMENT } from "../../../environments/environment";
import { RequestTimeoutError } from "../../../application/core/services/monitoring/sentry/error/RequestTimeoutError";
import { InternalsMonitor } from "../../../application/core/services/monitoring/InternalsMonitor";

/**
 * Previously, the captureAndReportResourceLoadingTimeout issue was recorded only in Sentry. Now,
 * it has been sent to every internal monitor.
 */
@Service()
export class ResourceLoadingTimeoutHandler {
  captureAndReportResourceLoadingTimeout(
    errorMessage: string,
    scriptLoadTimeout = ENVIRONMENT.SCRIPT_LOAD_TIMEOUT,
  ): OperatorFunction<any, any> {
    return (source: Observable<any>) =>
      source.pipe(
        timeout({
          each: scriptLoadTimeout,
          with: () => {
            const error = new RequestTimeoutError(errorMessage);
            InternalsMonitor.getInstance().recordIssue(error);
            return throwError(() => error);
          },
        }),
      );
  }
}
