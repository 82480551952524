import { IApplePayButtonFactory } from "../services/button/IApplePayButtonFactory";

export class MockedApplePayButtonFactory implements IApplePayButtonFactory {
  create(label: string, style: string, locale: string): HTMLElement {
    const button = document.createElement("button");
    // Set button text
    button.textContent = "Buy with Apple Pay";

    // Set button styles
    button.style.backgroundColor = "black";
    button.style.color = "white";
    button.style.padding = "10px 20px";
    button.style.borderRadius = "20px";
    button.style.border = "none";
    button.style.fontFamily = "Arial, sans-serif";
    button.style.fontSize = "16px";
    button.style.cursor = "pointer";
    return button;
  }
}
