import { IConfig } from "../../../../shared/model/config/IConfig";
import { PUBLIC_EVENTS } from "../../models/constants/EventTypes";
import { InterFrameCommunicator } from "../../../../shared/services/message-bus/InterFrameCommunicator";
import { FramesHub } from "../../../../shared/services/message-bus/FramesHub";
import { CONTROL_FRAME_IFRAME } from "../../models/constants/Selectors";
import { type IInternalsMonitor } from "./IInternalsMonitor";
import { InternalsMonitorUtil } from "./InternalsMonitorUtil";

/**
 * The secondary implementation of the internals monitor which does not
 * communicate directly with Sentry like SentryAppMonitor does.
 *
 * The purpose of this implementation is to provide non-parent components
 * with a mechanism to share errors with the parent component. These errors
 * will be captured and stored until the control frame is ready, at which
 * point they will be passed along to AppMonitors
 *
 */
export class InternalsMonitorBroadcaster implements IInternalsMonitor {
  private errorCache: { error: Error; context?: object }[] = [];
  private activityCache: { type: string; context?: object }[] = [];
  private isControlFrameReady: boolean = false;

  constructor(
    private readonly interframeCommunicator: InterFrameCommunicator,
    readonly framesHub: FramesHub,
  ) {
    framesHub.waitForFrame(CONTROL_FRAME_IFRAME).subscribe(() => {
      this.isControlFrameReady = true;
      this.flushErrorAndActivityBacklog();
    });
  }

  /**
   * Does nothing.
   * @param config Not used.
   */
  initialize(config: IConfig): void {
    throw new Error(
      "initialize was called, but this should never happen on this instance",
    );
  }

  recordIssue(error: Error, context?: object): void {
    if (!this.isControlFrameReady) {
      this.errorCache.push({ error, context });
    } else {
      this.sendErrorToControlFrame(error, context);
    }
  }

  recordActivity(type: string, context?: object): void {
    if (!this.isControlFrameReady) {
      this.activityCache.push({ type, context });
    } else {
      this.sendActivityToControlFrame(type, context);
    }
  }

  private flushErrorAndActivityBacklog() {
    for (const activity of this.activityCache) {
      this.sendActivityToControlFrame(activity.type, activity.context);
    }

    for (const error of this.errorCache) {
      this.sendErrorToControlFrame(error.error, error.context);
    }

    this.errorCache.length = 0;
    this.activityCache.length = 0;
  }

  private sendErrorToControlFrame(error: Error, context?: object): void {
    this.interframeCommunicator.sendToControlFrame({
      type: PUBLIC_EVENTS.APP_ERROR,
      data: InternalsMonitorUtil.constructErrorMessage(error, context || {}),
    });
  }

  private sendActivityToControlFrame(type: string, context?: object): void {
    this.interframeCommunicator.sendToControlFrame({
      type: PUBLIC_EVENTS.APP_ACTION_TRACKING,
      data: { type, context },
    });
  }
}
