import { type IInternalsMonitor } from "./IInternalsMonitor";

/**
 * Global class for referencing an internal monitor solution.
 */
export class InternalsMonitor {
  private static instance: IInternalsMonitor;

  // Can't create an instance of this
  /* istanbul ignore next */
  private constructor() {}

  /**
   * Assigns the monitor instance that will be used by the library. This is expected
   * to only ever be set once per application.
   *
   * @param instance The instance to assign.
   */
  public static setInstance(instance: IInternalsMonitor) {
    if (InternalsMonitor.instance) {
      throw new Error("You can only set the instance once");
    }

    if (!instance) {
      throw new Error(
        "InternalMonitor setInstance value cannot be null or undefined",
      );
    }

    InternalsMonitor.instance = instance;
  }

  /**
   * @returns true if an instance has been assigned, false otherwise
   */
  public static hasInstance(): boolean {
    return InternalsMonitor.instance != undefined;
  }

  /**
   * Get the monitor instance.
   *
   * @returns
   *    The global monitor.
   */
  public static getInstance(): IInternalsMonitor {
    if (!InternalsMonitor.instance) {
      throw new Error(
        "InternalMonitor getInstance was called too early, as no value has been set",
      );
    }

    return InternalsMonitor.instance;
  }

  /**
   * Resets the state of the object. Should only be used for unit-tests.
   */
  public static reset(): void {
    InternalsMonitor.instance = undefined;
  }
}
