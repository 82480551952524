import Joi from "joi";

import { GOOGLE_PAY_BUTTON_OPTIONS_SCHEMA } from "./GooglePayButtonOptionsSchema";
import { GOOGLE_PAY_PAYMENT_REQUEST_SCHEMA } from "./GooglePayPaymentRequestSchema";

export const GOOGLE_PAY_SCHEMA: Joi.ObjectSchema = Joi.object().keys({
  buttonOptions: GOOGLE_PAY_BUTTON_OPTIONS_SCHEMA.required(),
  merchantUrl: Joi.string(),
  paymentRequest: GOOGLE_PAY_PAYMENT_REQUEST_SCHEMA.required(),
});
