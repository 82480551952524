import { IPayPalCallbacks } from "../models/IPayPalCallbacks";
import { FallbackPayPalEventHandlers } from "./FallbackPayPalEventHandlers";

export class PayPalCallbackMerger {
  merge(callbacks: IPayPalCallbacks): IPayPalCallbacks {
    const fallback = new FallbackPayPalEventHandlers();
    return {
      onInit: callbacks.onInit ?? fallback.getOnInitHandler(),
      onClick: callbacks.onClick ?? fallback.getOnClickHandler(),
      onCreateOrder:
        callbacks.onCreateOrder ?? fallback.getCreateOrderHandler(),
      onShippingChanged:
        callbacks.onShippingChanged ?? fallback.getOnShippingChangedHandler(),
      onConfirmPayment:
        callbacks.onConfirmPayment ?? fallback.getOnConfirmPaymentHandler(),
    };
  }
}
