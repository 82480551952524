import { Service } from "typedi";
import { PUBLIC_EVENTS } from "../../../../../application/core/models/constants/EventTypes";
import { IMessageBusEvent } from "../../../../../application/core/models/IMessageBusEvent";
import { IMessageBus } from "../../../../../application/core/shared/message-bus/IMessageBus";

@Service()
export class ApplePayCancelAction {
  constructor(private messageBus: IMessageBus) {}

  execute(): void {
    const cancelQueryEvent: IMessageBusEvent<unknown> = {
      type: PUBLIC_EVENTS.APPLE_PAY_CANCELLED,
      data: undefined,
    };

    this.messageBus.publish(cancelQueryEvent);
  }
}
