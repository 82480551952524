import { ContainerInstance, Service } from "typedi";
import { REDUCER_TOKEN } from "../../../shared/dependency-injection/InjectionTokens";
import { CombinedReducer } from "./CombinedReducer";
import { IReducer } from "./IReducer";

@Service()
export class CombinedReducerFactory {
  constructor(private container: ContainerInstance) {}

  getCombinedReducer<T>(): IReducer<T> {
    return new CombinedReducer(this.container.getMany(REDUCER_TOKEN));
  }
}
