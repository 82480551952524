import { Token } from "typedi";
import { ISrcProvider } from "../../integrations/click-to-pay/digital-terminal/ISrcProvider";
import { IPayPalTokenSource } from "../../integrations/paypal/models/IPayPalTokenSource";
import { IPayPalJwtListener } from "../../integrations/paypal/client/IPayPalJwtListener";
import { IPayPalTokenPublisher } from "../../integrations/paypal/client/IPayPalTokenPublisher";
import { IGatewayPayPalService } from "../../integrations/paypal/application/IGatewayPayPalService";
import { IHttpPayPalTransportService } from "../../integrations/paypal/application/IHttpPayPalTransportService";
import { IApplePayButtonFactory } from "../../integrations/apple-pay/client/services/button/IApplePayButtonFactory";
import { IApplePayEventBinder } from "../../integrations/apple-pay/client/services/payment/IApplePayEventBinder";
import { IApplePayGatewayClient } from "../../integrations/apple-pay/application/services/apple-pay-payment-service/IApplePayGatewayClient";

export const SRC_PROVIDER_TOKEN = new Token<ISrcProvider>("src-provider");
export const PAYPAL_TOKEN_SOURCE_TOKEN = new Token<IPayPalTokenSource>(
  "paypal-token-source",
);

export const PAYPAL_TOKEN_PUBLISHER_TOKEN = new Token<IPayPalTokenPublisher>(
  "paypal-token-publisher",
);

export const PAYAPAL_JWT_LISTENER_TOKEN = new Token<IPayPalJwtListener>(
  "paypal-jwt-listener",
);

export const PAYPAL_GATEWAY_SERVICE_TOKEN = new Token<IGatewayPayPalService>(
  "paypal-gateway-service",
);

export const HTTP_PAYPAL_TRANSPORT_SERVICE_TOKEN =
  new Token<IHttpPayPalTransportService>("paypal-transport-service");

/**
 * ApplePay Tokens
 */

export const APPLE_PAY_BUTTON_FACTORY_TOKEN = new Token<IApplePayButtonFactory>(
  "applepay-button-factory",
);

export const APPLE_PAY_APPLE_PAY_EVENT_BINDER_TOKEN =
  new Token<IApplePayEventBinder>("applepay-event-binder");

export const APPLE_PAY_GATEWAY_SERVICE_TOKEN =
  new Token<IApplePayGatewayClient>("applepay-gateway-service");
