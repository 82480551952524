import { Observable, of, throwError } from "rxjs";
import { Service } from "typedi";
import { catchError, mapTo, takeUntil, tap } from "rxjs/operators";
import { IConfig } from "../../../shared/model/config/IConfig";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { APPLEPPAYPAYMENTMETHODWALLETSOURCE } from "../models/IApplePayPaymentMethod";
import { ofType } from "../../../shared/services/message-bus/operators/ofType";
import { IMessageBusEvent } from "../../../application/core/models/IMessageBusEvent";
import { IUpdateJwt } from "../../../application/core/models/IUpdateJwt";
import { ApplePayConfigService } from "./services/config/ApplePayConfigService";
import { IApplePayConfigObject } from "./services/config/IApplePayConfigObject";
import { ApplePayButtonManager } from "./services/button/ApplePayButtonManager";
import { ApplePaySessionManager } from "./services/session/ApplePaySessionManager";
import { ApplePaySessionAvailabilityChecker } from "./ApplePaySessionAvailabilityChecker";

@Service()
export class ApplePayClient {
  constructor(
    private applePayConfigService: ApplePayConfigService,
    private buttonManager: ApplePayButtonManager,
    private sessionManager: ApplePaySessionManager,
    private availabilityChecker: ApplePaySessionAvailabilityChecker,
    private messageBus: IMessageBus,
  ) {}

  init(config: IConfig): Observable<void> {
    return this.validateServiceIsAvailable(config).pipe(
      tap((config) => this.subscribeToJwtChangeEvent(config)),
      tap((config) => {
        const applePayConfigObject = this.resolveApplePayConfig(config);
        this.insertApplePayButton(config, applePayConfigObject);
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
      mapTo(undefined),
    );
  }

  private validateServiceIsAvailable(config: IConfig): Observable<IConfig> {
    const result = this.availabilityChecker.isAvailable();

    if (result.isAvailable) {
      return of(config);
    }

    return throwError(() => result.error);
  }

  private subscribeToJwtChangeEvent(config: IConfig): void {
    this.messageBus
      .pipe(
        ofType(PUBLIC_EVENTS.UPDATE_JWT),
        takeUntil(this.messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY))),
      )
      .subscribe((event: IMessageBusEvent<IUpdateJwt>) =>
        this.registerButtonAction(config, event.data.newJwt),
      );
  }

  private registerButtonAction(config: IConfig, jwt: string) {
    const applePayConfigObject = this.resolveApplePayConfig({
      ...config,
      jwt: jwt,
    });

    this.buttonManager.setButtonAction(
      config,
      applePayConfigObject,
      this.generateStartAction(applePayConfigObject),
    );
  }

  private resolveApplePayConfig(config: IConfig): IApplePayConfigObject {
    const applePayConfigObject = this.applePayConfigService.getConfig(config, {
      walletmerchantid: "",
      walletrequestdomain: window.location.hostname,
      walletsource: APPLEPPAYPAYMENTMETHODWALLETSOURCE,
      walletvalidationurl: "",
    });

    return applePayConfigObject;
  }

  private insertApplePayButton(
    config: IConfig,
    applePayConfigObject: IApplePayConfigObject,
  ): void {
    this.buttonManager.injectButtonIntoDom(applePayConfigObject);
    this.buttonManager.setButtonAction(
      config,
      applePayConfigObject,
      this.generateStartAction(applePayConfigObject),
    );
  }

  private generateStartAction(config: IApplePayConfigObject): () => void {
    return () => {
      this.sessionManager.launch(config);
    };
  }
}
