import { IGooglePayPaymentRequest } from "./IGooglePayPaymentRequest";
import { IStjsCompatibleGooglePayButtonOptions } from "./IStjsCompatibleGooglePayButtonOptions";
//eslint-disable-next-line @typescript-eslint/naming-convention
export const GooglePayConfigName = "googlePay";
//eslint-disable-next-line @typescript-eslint/naming-convention
export const GooglePayTestEnvironment = "TEST";
//eslint-disable-next-line @typescript-eslint/naming-convention
export const GooglePayProductionEnvironment = "PRODUCTION";

export interface IGooglePayConfig {
  buttonOptions: IStjsCompatibleGooglePayButtonOptions;
  merchantUrl?: string;
  paymentRequest: IGooglePayPaymentRequest;
}
