import { Subject } from "rxjs";

import { IPayPalOnShippingChangedActions } from "../models/IPayPalOnShippingChangedActions";
import { PayPalAddressChangeResult } from "./PayPalAddressChangeResult";

export class ShippingChangedResolver
  implements IPayPalOnShippingChangedActions
{
  constructor(
    private readonly notification: Subject<PayPalAddressChangeResult>,
  ) {}

  public reject(): void {
    this.notification.next(PayPalAddressChangeResult.REJECTED);
    this.notification.complete();
  }

  public resolve() {
    this.notification.next(PayPalAddressChangeResult.ACCEPTED);
    this.notification.complete();
  }
}
