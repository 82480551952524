import { IApplePaySession } from "../../models/IApplePaySession";
import { IApplePayShippingContactCallbackActions } from "../../models/IApplePayShippingContactCallbackActions";
import { RequestJwtUpdateHandler } from "../../../../../application/core/services/st-codec/RequestJwtUpdateHandler";
import { APPLE_PAY_STATUS } from "../session/models/ApplePayStatus";
import { IJwtEnhancedApplePayShippingContactUpdate } from "../../models/IJwtEnhancedApplePayShippingContactUpdate";

export class ApplePayShippingContactResolver
  implements IApplePayShippingContactCallbackActions
{
  constructor(
    private readonly session: IApplePaySession,
    private readonly versionNumber: number,
    private readonly jwtRequestHandler: RequestJwtUpdateHandler,
  ) {}

  public resolve(param: IJwtEnhancedApplePayShippingContactUpdate): void {
    if (this.versionNumber <= 2) {
      this.handleLegacyVersion(param);
    } else {
      this.handleCurrentVersion(param);
    }
    this.handleJwtUpdate(param);
  }

  // Handle older versions of Apple Pay
  private handleLegacyVersion(
    param: IJwtEnhancedApplePayShippingContactUpdate,
  ): void {
    const status = this.determineShippingStatus(param.errors);
    this.session.completeShippingContactSelection(
      status,
      param.newShippingMethods,
      param.newTotal,
      param.newLineItems,
    );
  }

  // Determines the shipping status based on errors
  private determineShippingStatus(
    errors: ApplePayJS.ApplePayError[] = [],
  ): number {
    return errors.length > 0
      ? APPLE_PAY_STATUS.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS
      : APPLE_PAY_STATUS.STATUS_SUCCESS;
  }

  // Handle current Apple Pay versions
  private handleCurrentVersion(
    param: IJwtEnhancedApplePayShippingContactUpdate,
  ): void {
    if (
      param.errors &&
      param.errors.length > 0 &&
      this.isApplePayErrorAvailable()
    ) {
      param.errors = this.setApplePayErrorForAppleDevice(param.errors);
    }
    // Ensure the structure passed to completeShippingContactSelection is valid
    this.session.completeShippingContactSelection(param);
  }

  // Check if ApplePayError is available
  private isApplePayErrorAvailable(): boolean {
    return (
      typeof window !== "undefined" &&
      // @ts-ignore ApplePayError may or may not be defined
      typeof window.ApplePayError !== "undefined"
    );
  }

  // Create ApplePayError objects for Apple devices
  private setApplePayErrorForAppleDevice(
    errors: ApplePayJS.ApplePayError[],
  ): ApplePayJS.ApplePayError[] {
    const applePayErrors: ApplePayJS.ApplePayError[] = [];
    errors.forEach((error) => {
      const { code, contactField, message } = error;
      applePayErrors.push(
        // @ts-ignore ApplePayError may or may not be defined
        new window.ApplePayError(code, contactField, message),
      );
    });
    return applePayErrors;
  }

  //If they provide the JWT key, allow them to call updateJWT without needing a reference to the ST object.
  private handleJwtUpdate(
    param: IJwtEnhancedApplePayShippingContactUpdate,
  ): void {
    if (param.jwt) {
      this.jwtRequestHandler.request(param.jwt);
    }
  }
}
