import { Service, Inject } from "typedi";
import { Subscription, fromEvent, merge, takeUntil, tap, throttle } from "rxjs";
import { PUBLIC_EVENTS } from "../../../../../application/core/models/constants/EventTypes";
import { IStartPaymentMethod } from "../../../../../application/core/services/payments/events/IStartPaymentMethod";
import { DomMethods } from "../../../../../application/core/shared/dom-methods/DomMethods";
import { IMessageBus } from "../../../../../application/core/shared/message-bus/IMessageBus";
import { APPLE_PAY_BUTTON_FACTORY_TOKEN } from "../../../../../client/dependency-injection/InjectionTokens";
import { EventScope } from "../../../../../client/integrations/constants/EventScope";
import {
  APPLE_PAY_PAYMENT_METHOD_NAME,
  APPLEPPAYPAYMENTMETHODWALLETSOURCE,
} from "../../../models/IApplePayPaymentMethod";
import { IApplePayConfigObject } from "../config/IApplePayConfigObject";
import { IConfig } from "../../../../../shared/model/config/IConfig";
import { ofType } from "../../../../../shared/services/message-bus/operators/ofType";
import { APPLE_PAY_BUTTON_ID } from "./ApplePayButtonProperties";
import { IApplePayButtonFactory } from "./IApplePayButtonFactory";

/**
 * This is the class that's responsible for injecting the ApplePay button onto the page
 * and ensuring that any custom actions can be bound to the button that was injected.
 */
@Service()
export class ApplePayButtonManager {
  /**
   * This gets set via injectButton. It's needed so we can reference it on the message bus with the PAYMENT_STARTED event.
   */
  private applePayConfig: IApplePayConfigObject;

  /**
   * The last action that got set via setButtonAction.
   */
  private customClickAction: () => void;

  /**
   * The handle on the most recent subscription to the "click" event of the button.
   */
  private previousSubscription: Subscription;

  /**
   * Using an interface here lets us import any old button factory,
   * be it an official ApplePay factory or something to be used with mocks.
   */
  constructor(
    @Inject(APPLE_PAY_BUTTON_FACTORY_TOKEN)
    private buttonFactory: IApplePayButtonFactory,
    private messageBus: IMessageBus,
  ) {}

  injectButtonIntoDom(applePayConfig: IApplePayConfigObject): void {
    this.applePayConfig = applePayConfig;
    const targetId =
      applePayConfig.applePayConfig.buttonPlacement || APPLE_PAY_BUTTON_ID;
    if (!this.isButtonInserted(targetId)) {
      DomMethods.appendChildStrictIntoDOM(
        targetId,
        this.buttonFactory.create(
          applePayConfig.applePayConfig.buttonText,
          applePayConfig.applePayConfig.buttonStyle,
          applePayConfig.applePayConfig.paymentRequest.countryCode,
        ),
      );
    }
  }

  setButtonAction(
    config: IConfig,
    applePayConfigObject: IApplePayConfigObject,
    action: () => void,
  ): void {
    this.applePayConfig = applePayConfigObject;
    this.customClickAction = action;
    this.bindActionWithButton(
      config,
      applePayConfigObject.applePayConfig.buttonPlacement,
    );
  }

  private isButtonInserted(targetId: string): boolean {
    const targetElement = document.getElementById(targetId);
    return !!targetElement && !!targetElement.querySelector("a");
  }

  private bindActionWithButton(
    config: IConfig,
    buttonId: string | undefined,
  ): void {
    const buttonElement = document.getElementById(
      buttonId || APPLE_PAY_BUTTON_ID,
    );
    if (!buttonElement)
      throw new Error("There is no Apple Pay button container in the form");

    this.bindActions(
      () => this.onBeforeApplePayPaymentButtonClicked(config),
      buttonElement,
    );
  }

  private unsubscribePrevious(): void {
    if (this.previousSubscription) {
      this.previousSubscription.unsubscribe();
    }
  }

  private bindActions(action: () => void, button: HTMLElement): void {
    this.unsubscribePrevious();

    // Define events that might terminate the button's action or modify its behavior.
    const destroyEvent = this.messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY));
    const submitEvent = this.messageBus.pipe(
      ofType(PUBLIC_EVENTS.CALL_MERCHANT_SUBMIT_CALLBACK),
    );
    const cancelledEvent = this.messageBus.pipe(
      ofType(PUBLIC_EVENTS.APPLE_PAY_CANCELLED),
    );

    // Combine the submit and cancelled events to throttle the click event based on these.
    const actionControlEvents = merge(
      submitEvent,
      cancelledEvent,
      destroyEvent,
    );

    // Listen for button clicks until an action control event occurs.
    this.previousSubscription = fromEvent(button, "click")
      .pipe(
        tap((e) => e.preventDefault()), // Prevent default to stop form from submitting.
        throttle(() => actionControlEvents),
        takeUntil(destroyEvent),
      )
      .subscribe(() => {
        action(); // Execute the provided action.
      });
  }

  /**
   * A check to determine if the merchant has specified that
   * ApplePay should be configured to not auto-start.
   *
   * Auto-start gets disabled by adding ["APPLEPAY"] to disabledAutoPaymentStart.
   *
   * @param config The APM config.
   *
   * @returns
   *  True if the merchant wants control over the payment
   * start action, rather than having the code execute it
   * directly
   */
  private isPaymentPrecheckEnabled(config: IConfig): boolean {
    return (config.disabledAutoPaymentStart ?? [])
      .map((paymentMethodName) => paymentMethodName.toLowerCase())
      .includes(APPLE_PAY_PAYMENT_METHOD_NAME.toLowerCase());
  }

  /**
   * A 'before' execute handler for the ApplePay button.
   *
   * When the ApplePay button is clicked, we first check to see
   * whether or not the auto-start setting is configured.
   *
   * If the config option `disabledAutoPaymentStart` contains APPLEPAY,
   * then we know that we should not auto-execute the onClick behaviour
   * and instead we should stop and publish an event to the merchant
   * with a callback they can execute if they are ready for the payment
   * to start.
   *
   * @param config The APM config.
   */
  private onBeforeApplePayPaymentButtonClicked(config: IConfig): void {
    const data: any = {
      /** @deprecated New clients should use ‘name’ instead, which is more consistent with other library callbacks. Note that the casing of the value will change */
      paymentOption: APPLEPPAYPAYMENTMETHODWALLETSOURCE,
      name: APPLE_PAY_PAYMENT_METHOD_NAME,
    };

    if (this.isPaymentPrecheckEnabled(config)) {
      data.paymentStart = this.onApplePayPaymentButtonClicked;
    }

    this.messageBus.publish(
      {
        type: PUBLIC_EVENTS.PAYMENT_METHOD_PRE_CHECK,
        data,
      },
      EventScope.EXPOSED,
    );

    if (!data.paymentStart) {
      this.onApplePayPaymentButtonClicked();
    }
  }

  /**
   * The 'actual' logic that should be executed when
   * the ApplePay button is clicked with the intention
   * of starting a payment.
   *
   * @param config The APM config.
   */
  private onApplePayPaymentButtonClicked = (): void => {
    this.messageBus.publish<IStartPaymentMethod<IApplePayConfigObject>>({
      type: PUBLIC_EVENTS.START_PAYMENT_METHOD,
      data: {
        data: this.applePayConfig,
        name: APPLE_PAY_PAYMENT_METHOD_NAME,
      },
    });

    if (this.customClickAction) {
      this.customClickAction();
    }
  };
}
