import { BehaviorSubject } from "rxjs";
import { IStatefulPayPalButtons } from "../models/IStatefulPayPalButtons";

export class StatefulPayPalButtons implements IStatefulPayPalButtons {
  constructor(private readonly notification: BehaviorSubject<boolean>) {}

  disable(): void {
    this.notification.next(false);
  }
  enable(): void {
    this.notification.next(true);
  }
  areEnabled(): boolean {
    return this.notification.getValue();
  }
}
