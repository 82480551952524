export class ScriptHostLocator {
  /**
   * Determines the HOST url of a script that has been listed on a page.
   *
   * @param scripts The script elements located on the page
   * @param libraryName The JS library to locate. The name should be the exact name of the script. No need to include a forward slash before it.
   * @returns The HOST url of the first matching script, or null if none could be found.
   */
  static getScriptHosts(
    scripts: HTMLCollectionOf<HTMLScriptElement>,
    libraryName: string,
  ): string[] {
    const matchingHosts: string[] = [];

    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      const src = scripts[i].src;
      if (src) {
        ScriptHostLocator.processUrl(src, libraryName, matchingHosts);
      } else {
        const content = script.textContent || script.innerText;
        if (!content) {
          continue;
        }
        const urls = ScriptHostLocator.extractUrls(content);
        urls.forEach((url) =>
          ScriptHostLocator.processUrl(url, libraryName, matchingHosts),
        );
      }
    }
    return matchingHosts; // Return null if no matching URL is found
  }
  private static processUrl(
    url: string,
    libraryName: string,
    matchingHosts: string[],
  ) {
    const srcWithoutQueryParam = url.toLowerCase().split("?")[0];
    if (srcWithoutQueryParam.endsWith(`/${libraryName}`)) {
      const parsedUrl = new URL(url);
      const resource = parsedUrl.pathname.replace(`/${libraryName}`, "");
      const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}${resource}`;
      matchingHosts.push(baseUrl);
    }
  }
  private static extractUrls(content: string) {
    const urlRegex = /(https?:\/\/[^\s"'`>]+)/g;
    const matches = content.match(urlRegex);
    return matches ? matches.map((url) => String(url)) : [];
  }
}
