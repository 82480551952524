import { ajax } from "rxjs/ajax";
import {
  ICardinal,
  IOrderObject,
} from "../../client/integrations/cardinal-commerce/ICardinal";
import { PAYMENT_EVENTS } from "../../application/core/models/constants/PaymentEvents";
import { ENVIRONMENT } from "../../environments/environment";
import { IValidationResult } from "../../client/integrations/cardinal-commerce/data/IValidationResult";

export class CardinalMock implements ICardinal {
  private callbacks = {
    [PAYMENT_EVENTS.SETUP_COMPLETE]: (...args: unknown[]): unknown => void 0,

    [PAYMENT_EVENTS.VALIDATED]: (...args: unknown[]): unknown => void 0,
  };

  constructor(private manualCallbackTrigger: boolean = false) {}

  configure(config: unknown): void {}

  continue(
    paymentBrand: string,
    continueObject: unknown,
    orderObject?: unknown,
    cardinalJwt?: string,
  ): void {
    if (this.manualCallbackTrigger) {
      return;
    }

    ajax({
      url: ENVIRONMENT.CARDINAL_COMMERCE.MOCK.AUTHENTICATE_CARD_URL,
      method: "GET",
    }).subscribe((response) => {
      const { data, jwt } = response.response as unknown as {
        data: IValidationResult;
        jwt: string;
      };
      this.callbacks[PAYMENT_EVENTS.VALIDATED](data, jwt);
    });
  }

  off(event: string): void {}

  on(eventName: string, callback: (...eventData: unknown[]) => void): void {
    this.callbacks[eventName] = callback;
  }

  setup(
    initializationType: "init" | "complete" | "confirm",
    initializationData: unknown,
  ): void {
    if (this.manualCallbackTrigger) {
      return;
    }

    setTimeout(() => this.callbacks[PAYMENT_EVENTS.SETUP_COMPLETE](), 100);
  }

  trigger(eventName: string, ...data: unknown[]): void {
    if (this.callbacks[eventName]) {
      this.callbacks[eventName].apply(this, data);
    }
  }

  start(paymentBrand: string, orderObject: IOrderObject, jwt?: string): void {
    if (this.manualCallbackTrigger) {
      return;
    }

    this.callbacks[PAYMENT_EVENTS.VALIDATED](
      {
        ActionCode: "ERROR",
        ErrorDescription: "",
        ErrorNumber: 4000,
        Validated: false,
      },
      "",
    );
  }
}
