import { DEFAULT_PLACEHOLDERS } from "../../../application/core/models/constants/config-resolver/DefaultPlaceholders";
import { ITokenizedCardPaymentConfig } from "./ITokenizedCardPayment";
//eslint-disable-next-line @typescript-eslint/naming-convention
export const TokenizedCardPaymentMethodName = "TokenizedCard";
//eslint-disable-next-line @typescript-eslint/naming-convention
export const TokenizedCardPaymentConfigName = "tokenizedCard";
//eslint-disable-next-line @typescript-eslint/naming-convention
export const TokenizedCardPaymentSecurityCode = "securitycode";
//eslint-disable-next-line @typescript-eslint/naming-convention
export const DefaultTokenizedCardPaymentConfig: ITokenizedCardPaymentConfig = {
  buttonId: "tokenized-submit-button",
  securityCodeSlotId: "st-tokenized-security-code",
  formId: "st-form-tokenized",
  placeholder: DEFAULT_PLACEHOLDERS.securitycode,
};
