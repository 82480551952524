import { Service, Inject } from "typedi";
import { ValidationError, ValidationResult } from "joi";
import { APM_SCHEMA, APMSCHEMASMAP } from "../../models/APMSchema";
import { IAPMConfig } from "../../models/IAPMConfig";
import { IAPMItemConfig } from "../../models/IAPMItemConfig";
import { IStJwtPayload } from "../../../../application/core/models/IStJwtPayload";
import { APM_JWT_SCHEMAS_MAP } from "../../models/APMJwtSchemasMap";
import { type IInternalsMonitor } from "../../../../application/core/services/monitoring/IInternalsMonitor";
import { MisconfigurationError } from "../../../../application/core/services/monitoring/sentry/error/MisconfigurationError";

@Service()
export class APMValidator {
  constructor(
    @Inject("IInternalsMonitor") private internalMonitor: IInternalsMonitor,
  ) {}

  validateConfig(config: IAPMConfig): ValidationResult {
    const validationResult = APM_SCHEMA.validate(config);
    this.handleApmDeprecatedFields(validationResult);
    return validationResult;
  }

  validateItemConfig(apm: IAPMItemConfig): ValidationError | null {
    if (!APMSCHEMASMAP.has(apm.name)) {
      return null;
    }

    const validationResult: ValidationResult = APMSCHEMASMAP.get(
      apm.name,
    ).validate(apm);
    this.handleApmDeprecatedFields(validationResult);

    return validationResult.error || null;
  }

  validateJwt(
    apm: IAPMItemConfig,
    jwtPayload: IStJwtPayload,
  ): ValidationError | null {
    if (!APM_JWT_SCHEMAS_MAP.has(apm.name)) {
      return null;
    }

    return APM_JWT_SCHEMAS_MAP.get(apm.name).validate(jwtPayload).error || null;
  }

  private handleApmDeprecatedFields(validationResult: ValidationResult) {
    if (validationResult.warning) {
      console.warn(validationResult.warning.message);
      this.internalMonitor.recordIssue(
        new MisconfigurationError(
          `Misconfiguration: ${validationResult.warning.message}`,
          validationResult.warning,
        ),
      );
    }
  }
}
