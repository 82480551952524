import {
  ICheckoutData,
  ICheckoutResponse,
  ICompleteIdValidationResponse,
  IConsumerIdentity,
  IIdentityLookupResponse,
  IInitiateIdentityValidationResponse,
  IIsRecognizedResponse,
  ISrc,
  ISrcInitData,
  ISrcProfileList,
  IUnbindAppInstanceResponse,
} from "../../ISrc";
import { SrcName } from "../../SrcName";
import { IVisaSrc } from "./IVisaSrc";

export class VisaSrcWrapper implements ISrc {
  private visaSrc: IVisaSrc;

  constructor(readonly initiatorId: string) {
    this.initiatorId = initiatorId;
    //@ts-ignore
    const vSrcAdapter = window.vAdapters.VisaSRCI;
    this.visaSrc = new vSrcAdapter();
  }

  async checkout(data: ICheckoutData): Promise<ICheckoutResponse> {
    return {
      ...(await this.visaSrc.checkout(data)),
      srcName: SrcName.VISA,
    };
  }

  completeIdentityValidation(
    validationData: string,
  ): Promise<ICompleteIdValidationResponse> {
    return this.visaSrc.completeIdentityValidation(validationData);
  }

  getSrcProfile(idTokens: string[]): Promise<ISrcProfileList> {
    return this.visaSrc.getSrcProfile(idTokens);
  }

  identityLookup(
    consumerIdentity: IConsumerIdentity,
  ): Promise<IIdentityLookupResponse> {
    return this.visaSrc.identityLookup(consumerIdentity).then((response) => ({
      ...response,
      srcName: SrcName.VISA,
    }));
  }

  init(initData: ISrcInitData | Partial<ISrcInitData>): Promise<void> {
    const visaInitData: Partial<ISrcInitData> = {
      srcInitiatorId: this.initiatorId,
      srciDpaId:
        typeof initData?.srciDpaId === "string"
          ? initData.srciDpaId
          : initData?.srciDpaId[SrcName.VISA],
    };

    return this.visaSrc.init({
      ...initData,
      ...visaInitData,
    } as ISrcInitData);
  }

  initiateIdentityValidation(): Promise<IInitiateIdentityValidationResponse> {
    return this.visaSrc.initiateIdentityValidation();
  }

  isRecognized(): Promise<IIsRecognizedResponse> {
    return this.visaSrc.isRecognized();
  }

  unbindAppInstance(idToken?: string): Promise<IUnbindAppInstanceResponse> {
    return this.visaSrc.unbindAppInstance(idToken);
  }
}
