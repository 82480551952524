import { IPayPalOnConfirmationData } from "../models/IPayPalOnConfirmationData";
import { IStatefulPayPalButtons } from "../models/IStatefulPayPalButtons";
import { IPayPalCallbackResolveAction } from "../models/IPayPalCallbackResolveAction";
import { IPayPalOnClickActions } from "../models/IPayPalOnClickActions";
import { IPayPalOnClickData } from "../models/IPayPalOnClickData";
import { IPayPalOnShippingChangedActions } from "../models/IPayPalOnShippingChangedActions";
import { IPayPalOnShippingParameters } from "../models/IPayPalOnShippingParameters";

/**
 * Contains the fallback event handlers we'll use for the PayPal
 * callbacks when the merchants haven't provided anything.
 *
 * When using all of these together, it does mean the merchant-developer
 * doesn't need to provide any callback implementation, as it will use the JWT
 * from the constructor, and then auto-enable buttons, before utilising gateway-provided JWT for the AUTH.
 */
export class FallbackPayPalEventHandlers {
  public getOnConfirmPaymentHandler(): (
    actions: IPayPalCallbackResolveAction,
    data: IPayPalOnConfirmationData,
  ) => void {
    return (
      actions: IPayPalCallbackResolveAction,
      data: IPayPalOnConfirmationData,
    ) => {
      actions.resolve();
    };
  }

  public getCreateOrderHandler(): (
    actions: IPayPalCallbackResolveAction,
  ) => void {
    return (actions: IPayPalCallbackResolveAction) => {
      actions.resolve();
    };
  }

  public getOnInitHandler(): (buttons: IStatefulPayPalButtons) => void {
    return (buttons: IStatefulPayPalButtons) => {
      buttons.enable();
    };
  }

  public getOnClickHandler(): (
    actions: IPayPalOnClickActions,
    data: IPayPalOnClickData,
  ) => void {
    return (actions: IPayPalOnClickActions, data: IPayPalOnClickData) => {
      actions.resolve();
    };
  }

  /**
   * When the callback is attached to on shipping, there's an extra loading screen
     but it isn't there when it's undefnined. To improve UX, we won't provide a default
     so that merchants who don't use this callback don't have to suffer from this load event.
   * @returns 
   */
  public getOnShippingChangedHandler(): (
    actions: IPayPalOnShippingChangedActions,
    data: IPayPalOnShippingParameters,
  ) => void {
    return undefined;
  }
}
