import {
  BehaviorSubject,
  combineLatest,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  throwError,
} from "rxjs";
import { Service } from "typedi";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { ITranslator } from "../../../../application/core/shared/translator/ITranslator";
import { SrcAggregate } from "../../digital-terminal/SrcAggregate";
import {
  ICompleteIdValidationResponse,
  IInitiateIdentityValidationResponse,
} from "../../digital-terminal/ISrc";
import { IIdentificationData } from "../../digital-terminal/interfaces/IIdentificationData";
import { SrcName } from "../../digital-terminal/SrcName";
import { IUserIdentificationService } from "../../digital-terminal/interfaces/IUserIdentificationService";
import { IMessageBus } from "../../../../application/core/shared/message-bus/IMessageBus";
import { IHPPClickToPayAdapterInitParams } from "./IHPPClickToPayAdapterInitParams";
import { HPPUpdateViewCallback } from "./HPPUpdateViewCallback";
import { CTPSignInEmail } from "./ctp-sign-in/CTPSignInEmail";
import { CTPSignInOTP } from "./ctp-sign-in/CTPSignInOTP";
import { MastercardCTPSIgnInOTP } from "./ctp-sign-in/mastercard/MastercardCTPSignInOTP";
import { VisaCTPSignInOTP } from "./ctp-sign-in/visa/VisaCTPSignInOTP";

@Service()
export class HPPUserIdentificationService
  implements IUserIdentificationService
{
  private initParams: IHPPClickToPayAdapterInitParams;
  private emailPrompt: CTPSignInEmail;
  private otpPrompt: CTPSignInOTP;
  private repeatTrigger$ = new BehaviorSubject(false);
  private identityType$ = new BehaviorSubject("");

  constructor(
    private translator: ITranslator,
    private messageBus: IMessageBus,
    private hppUpdateViewCallback: HPPUpdateViewCallback,
  ) {
    this.emailPrompt = new CTPSignInEmail(this.translator);
  }

  setInitParams(initParams: IHPPClickToPayAdapterInitParams) {
    this.initParams = initParams;
    this.emailPrompt.setContainer(this.initParams.signInContainerId);
  }

  private initOtpPrompt(srcName: SrcName) {
    this.otpPrompt =
      srcName === SrcName.MASTERCARD
        ? new MastercardCTPSIgnInOTP(this.translator)
        : new VisaCTPSignInOTP(this.translator);

    this.otpPrompt.setContainer(this.initParams.signInContainerId);

    this.otpPrompt.onCancel(() => this.repeatTrigger$.next(true));

    if (srcName === SrcName.MASTERCARD) {
      (this.otpPrompt as MastercardCTPSIgnInOTP).onPayAnotherWay(
        (value: string) => {
          if (value === "") {
            this.hppUpdateViewCallback.callUpdateViewCallback({
              displayCardForm: true,
              displaySubmitButton: true,
              displayMaskedCardNumber: null,
              displayCardType: null,
            });

            this.emailPrompt.close();
          } else {
            this.identityType$.next(value);
          }
        },
      );
    }
  }

  identifyUser(
    srcAggregate: SrcAggregate,
    identificationData?: IIdentificationData,
  ): Observable<ICompleteIdValidationResponse> {
    return this.repeatTrigger$.pipe(
      switchMap((forceEmailPrompt) => {
        let emailSource: Observable<string>;
        const shouldAskForEmail =
          !identificationData?.email || !!forceEmailPrompt;
        if (shouldAskForEmail) {
          emailSource = this.askForEmail();
        } else {
          emailSource = of(identificationData.email);
        }

        return this.getSrcNameForEmail(
          emailSource,
          srcAggregate,
          shouldAskForEmail,
        );
      }),
      switchMap((srcName) =>
        this.completeIdentification(srcName, srcAggregate),
      ),
    );
  }

  // TODO add typings for errorResponse
  private showEmailError(errorResponse) {
    this.emailPrompt.showError(this.getErrorMessage(errorResponse));
  }

  private getErrorMessage(errorResponse): string {
    const hasErrorDetails = errorResponse?.error?.details?.some(
      (error) => error.message,
    );
    const defaultUnknownErrorMessage = this.translator.translate(
      "Something went wrong, try again or use another email.",
    );

    if (errorResponse.reason === "ACCT_INACCESSIBLE") {
      return this.translator.translate("The user account has been blocked.");
    }

    switch (errorResponse.error?.reason) {
      case "ACCT_INACCESSIBLE":
        return this.translator.translate(
          "Account assigned to this email is currently not accessible",
        );
      case "CODE_EXPIRED":
        return this.translator.translate(
          'OTP code has expired. Click "resend" to generate new code.',
        );
      case "CODE_INVALID":
        return this.translator.translate(
          "The code you have entered is incorrect",
        );
      case "OTP_SEND_FAILED":
        return this.translator.translate("OTP code could not be sent.");
      case "RETRIES_EXCEEDED":
        return this.translator.translate(
          "The number of retries for generating the OTP exceeded the limit.",
        );
      case "ID_INVALID":
      case "SESSION_ID_INVALID":
        return this.translator.translate(
          "Invalid email. Use correct email and try again",
        );
      case "CONSUMER_ID_MISSING":
        return this.translator.translate(
          "Consumer identity is missing in the request.",
        );
      case "VALIDATION_DATA_MISSING":
      case "VALDATA_MISSING":
        return this.translator.translate("Validation data missing.");
      case "ID_FORMAT_UNSUPPORTED":
        return this.translator.translate("Email is not in valid format.");
      case "UNRECOGNIZED_CONSUMER_ID":
        return this.translator.translate(
          "Consumer email could not be recognized.",
        );
      case "FRAUD":
        return this.translator.translate(
          "The user account was locked or disabled.",
        );
      case "UNRECOGNIZED_EMAIL":
        return this.translator.translate(
          "The email address you have entered is not registered for Click to Pay.",
        );
      default:
        return hasErrorDetails
          ? errorResponse.error.details.map((e) => e.message).join("\n")
          : defaultUnknownErrorMessage;
    }
  }

  private getSrcNameForEmail(
    emailSource: Observable<string>,
    srcAggregate: SrcAggregate,
    captureErrors: boolean,
  ): Observable<SrcName> {
    return emailSource.pipe(
      switchMap((email) =>
        srcAggregate
          .identityLookup({
            type: "EMAIL",
            identityValue: email,
          })
          .pipe(
            switchMap((result) => {
              if (result?.consumerPresent === false) {
                return throwError(() => ({
                  error: { reason: "UNRECOGNIZED_EMAIL" },
                }));
              }
              return of(result);
            }),
            filter((result) => result?.consumerPresent),
            map((result) => result.srcNames[0]),
            catchError((errorResponse) => {
              if (captureErrors) {
                this.showEmailError(errorResponse);
                return NEVER;
              } else {
                return throwError(errorResponse);
              }
            }),
          ),
      ),
    );
  }

  private completeIdentification(
    srcName: SrcName,
    srcAggregate: SrcAggregate,
  ): Observable<ICompleteIdValidationResponse> {
    const codeSendTrigger = new BehaviorSubject<boolean>(true);

    return combineLatest([
      of(srcName),
      codeSendTrigger,
      this.identityType$,
    ]).pipe(
      map(([srcName]) => srcName),
      switchMap((srcName) =>
        srcAggregate
          .initiateIdentityValidation(srcName, this.identityType$.value)
          .pipe(
            switchMap((validationResponse) =>
              this.askForCode(validationResponse, codeSendTrigger, srcName),
            ),
            switchMap((code) =>
              srcAggregate.completeIdentityValidation(srcName, code).pipe(
                tap(() => this.emailPrompt.close()),
                catchError((error) => this.handleInvalidOTPCode(error)),
              ),
            ),
          ),
      ),
    );
  }

  private handleInvalidOTPCode(errorResponse) {
    this.otpPrompt.showError(this.getErrorMessage(errorResponse));
    return NEVER;
  }

  private askForEmail(): Observable<string> {
    this.hppUpdateViewCallback.callUpdateViewCallback({
      displayCardForm: true,
      displaySubmitButton: true,
      displayMaskedCardNumber: null,
      displayCardType: null,
    });

    return this.emailPrompt.show();
  }

  private askForCode(
    validationResponse: IInitiateIdentityValidationResponse,
    resendSubject: BehaviorSubject<boolean>,
    srcName: SrcName,
  ): Observable<string> {
    const resultSubject = new ReplaySubject<string>();

    this.hppUpdateViewCallback.callUpdateViewCallback({
      displayCardForm: false,
      displaySubmitButton: false,
      displayMaskedCardNumber: null,
      displayCardType: null,
    });
    this.emailPrompt.close();

    this.initOtpPrompt(srcName);

    this.otpPrompt.show(validationResponse, resultSubject, resendSubject);
    return resultSubject;
  }
}
