import { Service } from "typedi";
import {
  SUPPORTED_ANDROID_VERSIONS,
  SUPPORTED_IOS_VERSIONS,
} from "../SupportedOSs";

@Service()
export class DeviceSupport {
  isDeviceSupported(version: string, name: string): boolean {
    if (version === undefined) {
      return false;
    }

    const dotIndex: number = version.indexOf(".");

    if (name === "Android" && dotIndex === -1) {
      return this.hasMainVersion(SUPPORTED_ANDROID_VERSIONS, version);
    }
    if (name === "Android" && dotIndex !== -1) {
      return this.hasSpecificVersion(
        SUPPORTED_ANDROID_VERSIONS,
        version,
        dotIndex,
      );
    }
    if (name === "iOS" && dotIndex === -1) {
      return this.hasMainVersion(SUPPORTED_IOS_VERSIONS, version);
    }
    if (name === "iOS" && dotIndex !== -1) {
      return this.hasSpecificVersion(SUPPORTED_IOS_VERSIONS, version, dotIndex);
    }

    return true;
  }

  private hasMainVersion(array: number[], version: string): boolean {
    return array.includes(Number(Number(version).toFixed(0)));
  }

  private hasSpecificVersion(
    array: number[],
    version: string,
    dotIndex: number,
  ): boolean {
    return array.includes(
      Number(Number(version.substr(0, dotIndex)).toFixed(0)),
    );
  }
}
