import { Observable, map } from "rxjs";
import { Service } from "typedi";
import { PAYAPAL_JWT_LISTENER_TOKEN } from "../../../client/dependency-injection/InjectionTokens";

import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { IMessageBusEvent } from "../../../application/core/models/IMessageBusEvent";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IStartPaymentMethod } from "../../../application/core/services/payments/events/IStartPaymentMethod";
import { ofType } from "../../../shared/services/message-bus/operators/ofType";
import { IPayPalGatewayRequest } from "../models/IPayPalPayRequest";
import { IPayPalJwtListener } from "./IPayPalJwtListener";

/**
 * Listens for the event of when the PayPal modal has been visually rendered and
 * the merchant developer has supplied an ORDER JWT.
 *
 * Event event returns the JWT that should be associated with the order creation.
 */
@Service({ id: PAYAPAL_JWT_LISTENER_TOKEN, multiple: true })
export class MessageBusPayPalJwtListener implements IPayPalJwtListener {
  constructor(private readonly messageBus: IMessageBus) {}

  orderJwtAvailable(): Observable<string | null> {
    return this.messageBus.pipe(
      ofType(PUBLIC_EVENTS.PAYPAL_CREATE_ORDER_REQUEST),
      map(
        (event: IMessageBusEvent<IStartPaymentMethod<IPayPalGatewayRequest>>) =>
          event?.data?.data?.jwt ?? null,
      ),
    );
  }
}
