// TODO remove fields that are not used by click to pay adapter
export enum HPPFormFieldName {
  PAN = "pan",
  CARD_EXPIRY_MONTH = "expirymonth",
  CARD_EXPIRY_YEAR = "expiryyear",
  CARD_SECURITY_CODE = "securitycode",
  REGISTER = "register",
  BILLING_PREFIX_NAME = "billingprefixname",
  BILLING_FIRST_NAME = "billingfirstname",
  BILLING_LAST_NAME = "billinglastname",
  BILLING_PREMISE = "billingpremise",
  BILLING_STREET = "billingstreet",
  BILLING_TOWN = "billingtown",
  BILLING_COUNTY = "billingcounty",
  BILLING_COUNTRY_ISO2A = "billingcountryiso2a",
  BILLING_POSTCODE = "billingpostcode",
  BILLING_EMAIL = "billingemail",
  BILLING_TELEPHONE = "billingtelephone",
  BILLING_TELEPHONE_TYPE = "billingtelephonetype",
  SRC_CARD_ID = "srcDigitalCardId",
}
