import { Service } from "typedi";
import { IStartPaymentMethod } from "../../../application/core/services/payments/events/IStartPaymentMethod";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { IGooglePayGatewayRequest } from "../../../integrations/google-pay/models/IGooglePayRequest";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { PaymentStatus } from "../../../application/core/services/payments/PaymentStatus";
import {
  GOOGLE_PAYMENT_METHOD_NAME,
  GOOGLE_PAYMENT_METHOD_WALLET_SOURCE,
} from "../../../integrations/google-pay/models/IGooglePaymentMethod";
import { TERM_URL } from "../../../application/core/models/constants/RequestData";
import { PaymentResultHandler } from "../../../application/core/services/payments/PaymentResultHandler";
import { IPaymentResult } from "../../../application/core/services/payments/IPaymentResult";

@Service()
export class GooglePayPaymentService {
  constructor(
    private messageBus: IMessageBus,
    private paymentResultHandler: PaymentResultHandler,
  ) {}

  processPayment(
    formData: Record<string, unknown>,
    payment: google.payments.api.PaymentData,
  ): void {
    this.messageBus.publish<IStartPaymentMethod<IGooglePayGatewayRequest>>({
      type: PUBLIC_EVENTS.START_PAYMENT_METHOD,
      data: {
        data: {
          walletsource: GOOGLE_PAYMENT_METHOD_WALLET_SOURCE,
          wallettoken: JSON.stringify(payment),
          ...formData,
          termurl: TERM_URL,
        },
        name: GOOGLE_PAYMENT_METHOD_NAME,
      },
    });
  }

  cancelPayment(formData: Record<string, unknown>): void {
    const result: IPaymentResult<unknown> = {
      status: PaymentStatus.CANCEL,
      data: {
        ...formData,
        errormessage: PaymentStatus.CANCEL,
        errorcode: "1",
        walletsource: GOOGLE_PAYMENT_METHOD_WALLET_SOURCE,
      },
      paymentMethodName: GOOGLE_PAYMENT_METHOD_NAME,
    };
    this.paymentResultHandler.handle(result);
  }

  errorPayment(formData: Record<string, unknown>): void {
    const result: IPaymentResult<unknown> = {
      status: PaymentStatus.ERROR,
      data: {
        ...formData,
        errormessage: PaymentStatus.ERROR,
        errorcode: "1",
        walletsource: GOOGLE_PAYMENT_METHOD_WALLET_SOURCE,
      },
      paymentMethodName: GOOGLE_PAYMENT_METHOD_NAME,
    };
    this.paymentResultHandler.handle(result);
  }
}
