import { Service } from "typedi";
import { IConfig } from "../../../shared/model/config/IConfig";
import { IGooglePayPaymentRequest } from "../../../integrations/google-pay/models/IGooglePayPaymentRequest";

@Service()
export class GooglePayConfigMapper {
  createPaymentDataRequest(config: IConfig): IGooglePayPaymentRequest {
    const {
      apiVersion,
      apiVersionMinor,
      allowedPaymentMethods,
      merchantInfo,
      transactionInfo: {
        countryCode,
        currencyCode,
        totalPriceStatus,
        totalPrice,
        totalPriceLabel,
        displayItems,
      },
      // lint disabled due to config property
      // eslint-disable-next-line @typescript-eslint/naming-convention
      shippingAddressRequired,
      callbackIntents,
      shippingAddressParameters,
      // lint disabled due to config property
      // eslint-disable-next-line @typescript-eslint/naming-convention
      shippingOptionRequired,
      // lint disabled due to config property
      // eslint-disable-next-line @typescript-eslint/naming-convention
      emailRequired,
    } = config.googlePay.paymentRequest;

    /**
     * We've found that if displayItems is set but no callbacks are, then
     * we run into a developer error. To avoid this, we add the check.
     */
    const canIncludeDisplayItems =
      Object.keys(config.googlePay.paymentRequest.paymentDataCallbacks ?? {})
        .length > 0;

    const paymentDataRequest = Object.assign(
      {},
      {
        apiVersion,
        apiVersionMinor,
        allowedPaymentMethods,
        transactionInfo: {
          countryCode,
          currencyCode,
          totalPriceStatus,
          totalPrice,
          totalPriceLabel,
          ...(canIncludeDisplayItems ? { displayItems } : {}),
        },
        merchantInfo: {
          merchantName: merchantInfo.merchantName,
          merchantId: merchantInfo.merchantId,
          merchantOrigin: merchantInfo.merchantOrigin,
          authJwt: merchantInfo.authJwt,
        },
        shippingAddressRequired,
        callbackIntents,
        shippingAddressParameters,
        shippingOptionRequired,
        emailRequired,
      },
    );

    return paymentDataRequest;
  }
}
