import { Service } from "typedi";
import packageInfo from "../../../package.json";
import { IAppVersion } from "./IAppVersion";

/**
 * Default class to get application version number retrieved from package.json
 * DEV NOTE: The pipeline sets the version in package.json
 */
@Service()
export class AppVersion implements IAppVersion {
  currentAppVersion: string;
  constructor() {
    this.currentAppVersion = packageInfo.version;
  }
  getVersion(): string {
    return this.currentAppVersion ?? "";
  }
  getMajorVersion(): string {
    return this.getVersion().split(".")[0] ?? "";
  }
  getMinorVersion(): string {
    return this.getVersion().split(".")[1] ?? "";
  }
  getPatchVersion(): string {
    return this.getVersion().split(".")[2] ?? "";
  }
}
