import { Subject } from "rxjs";
import { CTPSignInOTP } from "../CTPSignInOTP";
import { ITranslator } from "../../../../../../application/core/shared/translator/ITranslator";
import {
  IMastercardIdentityValidationChannel,
  IMastercardInitiateIdentityValidationResponse,
  MasterCardIdentityType,
} from "../../../../digital-terminal/src/mastercard/IMastercardSrc";
import {
  CODE_INPUTS_TEMPLATE,
  HEADER_TEMPLATE,
  SUBMIT_BUTTON_TEMPLATE,
} from "../CTPSignInUtils";

const logo = require("../../../../../../application/core/services/icon/images/click-to-pay.svg");

export class MastercardCTPSIgnInOTP extends CTPSignInOTP {
  supportedValidationChannelsForm: HTMLFormElement =
    document.createElement("form");
  private payAnotherWayCallback: (
    identityType: MasterCardIdentityType | string,
  ) => void;

  constructor(protected override translator: ITranslator) {
    super(translator);
  }

  override show(
    validationResponse: IMastercardInitiateIdentityValidationResponse,
    resultSubject: Subject<string>,
    resendSubject: Subject<boolean>,
  ) {
    super.show(validationResponse, resultSubject, resendSubject);
    this.generateValidationCodeForm();
    this.appendCodeFormAddListeners(resultSubject);

    this.validationCodeForm
      .querySelector("#st-ctp-prompt-continue-another-way")
      .addEventListener("click", () => {
        this.validationCodeForm.remove();
        this.showSupportedValidationChannels(
          validationResponse.supportedValidationChannels,
        );
      });
  }

  onPayAnotherWay(callback: any) {
    this.payAnotherWayCallback = callback;
  }

  private generateValidationCodeForm() {
    this.validationCodeForm.innerHTML = `
      <div class='st-ctp-prompt__otp-wrapper'>
        ${HEADER_TEMPLATE(this.closeButtonId, logo)}
        <div class='st-hpp-prompt__title'><strong>${this.translator.translate(
          "Access your Click to Pay cards",
        )}</strong></div>
        <div class='st-hpp-prompt__descrption'>${this.translator
          .translate(
            "Enter the code Mastercard sent to {{validationChannel}} to confirm it&#39;s you.",
          )
          .replace("{{validationChannel}}", this.validationChannels.join(","))}
      </div>
      ${CODE_INPUTS_TEMPLATE(
        this.fieldClass,
        this.errorFieldClass,
        this.otpInputsNames,
      )}
      <a class='st-hpp-prompt__link' id='st-ctp-prompt-continue-another-way' href='#'>${this.translator.translate(
        "Continue another way",
      )}</a>
      ${SUBMIT_BUTTON_TEMPLATE(this.translator.translate("Continue"))}
     </div>`;
  }

  private generateSupportedValidationChannelsForm(supportedChannels) {
    this.supportedValidationChannelsForm.innerHTML = `
     <div class='st-ctp-prompt__otp-wrapper'>
     <div class='st-hpp-prompt__title'><strong>${this.translator.translate(
       "Continue another way",
     )}</strong></div>
        ${HEADER_TEMPLATE(this.closeButtonId, logo)}
        <div class='st-ctp-prompt__otp-radioList'>
         ${supportedChannels
           ?.map(
             (channel: IMastercardIdentityValidationChannel, index: number) => {
               return `<div class='st-ctp-prompt__otp-radio'>
                              <input class='st-ctp-prompt__otp-input--radio' type='radio' id='${
                                channel.identityType
                              }' name='channel' value='${
                                channel.identityType
                              }' ${!index ? "checked" : ""}>
                              <label class='st-ctp-prompt__otp-input--radio-label' for='${
                                channel.identityType
                              }'>${this.translator.translate(
                                "Send another code to",
                              )} ${channel.maskedValidationChannel}</label>
                             </div>`;
             },
           )
           .join(" ")}
          <div class='st-ctp-prompt__otp-radio'>
            <input class='st-ctp-prompt__otp-input--radio' type='radio' id='anotherWay' name='channel' value=''>
            <label class='st-ctp-prompt__otp-input--radio-label' for='anotherWay'>${this.translator.translate(
              "Pay another way",
            )}</label>
          </div>
         </div>
         ${SUBMIT_BUTTON_TEMPLATE(this.translator.translate("Continue"))}
     </div>`;
  }

  private showSupportedValidationChannels(
    supportedChannels: IMastercardIdentityValidationChannel[],
  ) {
    this.generateSupportedValidationChannelsForm(supportedChannels);
    this.wrapperForm.appendChild(this.supportedValidationChannelsForm);
    this.addEventCancelForm(
      this.supportedValidationChannelsForm,
      this.closeButtonId,
    );
    this.supportedValidationChannelsForm.addEventListener("submit", (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (
        this.supportedValidationChannelsForm.elements["channel"].value === ""
      ) {
        this.close();
      }

      this.payAnotherWayCallback(
        this.supportedValidationChannelsForm.elements["channel"].value,
      );
    });
  }
}
