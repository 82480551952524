import { Service } from "typedi";
import { IApplePaySupportedNetworks } from "./IApplePaySupportedNetworks";
import {
  VERSION_ELEVEN_NETWORKS,
  VERSION_FIVE_NETWORKS,
  VERSION_FOUR_NETWORKS,
  VERSION_FOURTEEN_NETWORKS,
  VERSION_ONE_NETWORKS,
  VERSION_THIRTEEN_NETWORKS,
  VERSION_TWO_NETWORKS,
} from "./ApplePaySupportedNetworks";

@Service()
export class ApplePayNetworksService {
  private readonly versionToNetworksMap: Record<
    number,
    IApplePaySupportedNetworks[]
  > = {
    1: VERSION_ONE_NETWORKS,
    2: VERSION_TWO_NETWORKS,
    3: VERSION_TWO_NETWORKS,
    4: VERSION_FOUR_NETWORKS,
    5: VERSION_FIVE_NETWORKS,
    6: VERSION_FIVE_NETWORKS,
    7: VERSION_FIVE_NETWORKS,
    8: VERSION_FIVE_NETWORKS,
    9: VERSION_FIVE_NETWORKS,
    10: VERSION_FIVE_NETWORKS,
    11: VERSION_ELEVEN_NETWORKS,
    12: VERSION_ELEVEN_NETWORKS,
    13: VERSION_THIRTEEN_NETWORKS,
    14: VERSION_FOURTEEN_NETWORKS,
  };

  setSupportedNetworks(
    version: number,
    paymentRequestNetworks: IApplePaySupportedNetworks[],
  ): IApplePaySupportedNetworks[] {
    const supportedNetworks = this.getSupportedNetworks(version);

    if (!paymentRequestNetworks.length) {
      return supportedNetworks;
    }

    return paymentRequestNetworks.filter((network) =>
      supportedNetworks.includes(network),
    );
  }

  private getSupportedNetworks(version: number): IApplePaySupportedNetworks[] {
    return this.versionToNetworksMap[version] || VERSION_ONE_NETWORKS;
  }
}
