import { IScriptOriginTag } from "./interfaces/IScriptOriginTag";

export class ScriptOriginTagger {
  /**
   * Gets the most appropriate ScriptOriginTag for the given URL.
   *
   * @param url The URL that the script was downloaded from.
   *
   * @returns
   *  The most appropriate origin tag for the URL
   */
  getTag(url: string | null): IScriptOriginTag {
    let tag = OriginTag.UNKNOWN;

    if (!url) {
      url = "";
    } else if (
      url.includes("https://cdn.eu.trustpayments.com/js-payments-v3")
    ) {
      tag = OriginTag.CDN_1_EU_V3;
    } else if (url.includes("https://cdn.us.trustpayments.us/js-payments-v3")) {
      tag = OriginTag.CDN_1_US_V3;
    } else if (url.includes("https://cdn.eu.trustpayments.com/js")) {
      tag = OriginTag.CDN_2_EU_V3;
    } else if (url.includes("https://cdn.us.trustpayments.us/js")) {
      tag = OriginTag.CDN_2_US_V3;
    } else if (url.includes("https://webservices.securetrading.net/js/v3")) {
      tag = OriginTag.WS_EU_V3;
    } else if (url.includes("https://webservices.securetrading.us/js")) {
      tag = OriginTag.WS_US_V3;
    } else {
      tag = OriginTag.CUSTOM;
    }

    return {
      tag: OriginTag[tag],
      url: url,
    };
  }
}

export enum OriginTag {
  CDN_1_EU_V3 = "CDN_1_EU_V3",
  CDN_1_US_V3 = "CDN_1_US_V3",
  CDN_2_EU_V3 = "CDN_2_EU_V3",
  CDN_2_US_V3 = "CDN_2_US_V3",
  WS_EU_V3 = "WS_EU_V3",
  WS_US_V3 = "WS_US_V3",
  CUSTOM = "CUSTOM",
  UNKNOWN = "UNKNOWN",
}
