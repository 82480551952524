import { JWK } from "jose";

export const MASTERCARD_PROD_JWK: JWK = {
  kty: "RSA",
  e: "AQAB",
  use: "enc",
  kid: "151094-src-fpan-encryption",
  key_ops: ["encrypt", "wrapKey"],
  alg: "RSA-OAEP-256",
  n: "5_5zsPropSalLcRMXAuIm-7ClH70hKrW9G-fG_vEf3eosKM-NvV7gL01U9g2QOxa0kA2OuX0HSP8AySSu8H9ILKPhboqFdLSU2rh-tf4HkyWPzApub_l-8gSoRcPZJv6v6rXCWReVAkSxQ_K2jyrVneY8O7BTP3bsq0jjnD5WHV-TdLC39lL3PLbcqwIbCuoI_QxJIzhnLeNDoJpck1G16-hdCBJ1tOckH_5V3mHK3ufXNhJLCGPLS2zsGXx_VyFdlC9rq7g5ixpykPd6yfamKE2wQ8WOdyBXFLz_dj_TWTij5lfFBA8fHezI_zVIkizTVGi3Z2G7ItAfnqzaRWWKQ",
};
