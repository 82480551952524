import { IComponentsIds } from "../../../../../shared/model/config/IComponentsIds";
import {
  ANIMATED_CARD_INPUT_SELECTOR,
  CARD_NUMBER_INPUT_SELECTOR,
  EXPIRATION_DATE_INPUT_SELECTOR,
  NOTIFICATION_FRAME_ID,
  SECURITY_CODE_INPUT_SELECTOR,
} from "../Selectors";

export const DEFAULT_COMPONENTS_IDS: IComponentsIds = {
  animatedCard: ANIMATED_CARD_INPUT_SELECTOR,
  cardNumber: CARD_NUMBER_INPUT_SELECTOR,
  expirationDate: EXPIRATION_DATE_INPUT_SELECTOR,
  notificationFrame: NOTIFICATION_FRAME_ID,
  securityCode: SECURITY_CODE_INPUT_SELECTOR,
};
