import HttpClient from "@trustpayments/http-client";
import {
  asapScheduler,
  firstValueFrom,
  Observable,
  of,
  scheduled,
  throwError,
} from "rxjs";
import { switchMap } from "rxjs/operators";
import { Service } from "typedi";
import { ENVIRONMENT } from "../../../../environments/environment";
import { IGooglePayPaymentRequest } from "../../../../integrations/google-pay/models/IGooglePayPaymentRequest";
import { IGooglePaySessionPaymentsClient } from "../../../../integrations/google-pay/models/IGooglePaySessionPaymentsClient";
import { IConfig } from "../../../../shared/model/config/IConfig";
import { IGooglePaySdkProvider } from "./IGooglePaySdkProvider";
import { IGooglePayIsReadyToPayRequest } from "../../../../integrations/google-pay/models/IGooglePayIsReadyToPayRequest";

interface IErrorResponse {
  error: {
    statusCode: "ERROR" | "CANCELED";
  };
}

@Service()
export class GooglePaySdkProviderMock implements IGooglePaySdkProvider {
  private mockPaymentUrl: string = ENVIRONMENT.GOOGLE_PAY.MOCK_DATA_URL;

  constructor(private httpClient: HttpClient) {}

  setupSdk$(config: IConfig): Observable<IGooglePaySessionPaymentsClient> {
    return scheduled([this.getClientMock()], asapScheduler);
  }

  private getClientMock(): IGooglePaySessionPaymentsClient {
    return {
      isReadyToPay(
        request: IGooglePayIsReadyToPayRequest,
      ): Promise<google.payments.api.IsReadyToPayResponse> {
        return Promise.resolve({ result: true });
      },
      loadPaymentData: this.loadPaymentData.bind(this),
      createButton: this.createButton.bind(this),
      prefetchPaymentData: (
        request: google.payments.api.PaymentDataRequest,
      ) => {
        throw new Error("Function not implemented.");
      },
    };
  }

  private loadPaymentData(
    request: IGooglePayPaymentRequest,
  ): Promise<google.payments.api.PaymentData> {
    return firstValueFrom(
      this.httpClient
        .get$<
          google.payments.api.PaymentData | IErrorResponse
        >(this.mockPaymentUrl)
        .pipe(
          switchMap(({ data }) => {
            const isErrorResponse = (
              response: typeof data,
            ): response is IErrorResponse => {
              return Object.prototype.hasOwnProperty.call(response, "error");
            };

            if (isErrorResponse(data)) {
              return throwError(() => data.error);
            }

            return of(data);
          }),
        ),
    );
  }

  private createButton(config: google.payments.api.ButtonOptions) {
    const button = document.createElement("button");
    button.id = "gp-mocked-button";
    button.innerHTML =
      '<img src="./img/google-pay-button.svg" alt="Buy with Google Pay" style="max-width:100%;">';
    button.setAttribute(
      "style",
      `
      appearance: none;
      background: transparent;
      padding: 0;
      border: 0;
      outline: none;
      cursor: pointer;
    `,
    );
    button.setAttribute("type", "button");
    button.addEventListener("click", (event) => {
      event.preventDefault();
      config.onClick(event);
    });

    return button;
  }
}
