import { Service } from "typedi";
import { PUBLIC_EVENTS } from "../../../../../application/core/models/constants/EventTypes";
import { CONTROL_FRAME_IFRAME } from "../../../../../application/core/models/constants/Selectors";
import { IMessageBusEvent } from "../../../../../application/core/models/IMessageBusEvent";
import { DomMethods } from "../../../../../application/core/shared/dom-methods/DomMethods";
import { IFrameQueryingService } from "../../../../../shared/services/message-bus/interfaces/IFrameQueryingService";
import { IApplePayProcessPaymentResponse } from "../../../application/services/apple-pay-payment-service/IApplePayProcessPaymentResponse";
import { IApplePayGatewayRequest } from "../../../models/IApplePayRequest";
import { IApplePaySession } from "../../models/IApplePaySession";

import { APPLE_PAY_STATUS } from "../session/models/ApplePayStatus";
import "./PaymentAuthorization.scss";
import { ApplePayOverlayService } from "./ApplePayOverlayService";
@Service()
export class ApplePayPaymentAuthorizationAction {
  constructor(
    private readonly frameQueryingService: IFrameQueryingService,
    private readonly overlayService: ApplePayOverlayService,
  ) {}

  execute(
    applePaySession: IApplePaySession,
    formId: string,
    event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
  ) {
    const applePayStFormElement = document.getElementById("st-form"); // TODO: FIX ISSUE, GET FROM CONFIG
    if (applePayStFormElement) {
      this.overlayService.show(applePayStFormElement);
    }

    const formData = DomMethods.parseForm(formId);

    const paymentAuthorizedQueryEvent: IMessageBusEvent<IApplePayGatewayRequest> =
      {
        type: PUBLIC_EVENTS.APPLE_PAY_AUTHORIZATION_2,
        data: {
          ...formData,
          walletsource: "APPLEPAY",
          wallettoken: JSON.stringify(event.payment),
          termurl: "https://termurl.com",
        },
      };

    // send query to application side
    this.frameQueryingService
      .query(paymentAuthorizedQueryEvent, CONTROL_FRAME_IFRAME)
      .subscribe({
        next: (response: IApplePayProcessPaymentResponse) => {
          this.overlayService.hide();
          if (Number(response.errorcode) === 0) {
            applePaySession.completePayment({
              status: APPLE_PAY_STATUS.STATUS_SUCCESS,
            });
          } else {
            applePaySession.completePayment({
              status: APPLE_PAY_STATUS.STATUS_FAILURE,
            });
          }
        },
        error: () => {
          this.overlayService.hide();
          applePaySession.completePayment({
            status: APPLE_PAY_STATUS.STATUS_FAILURE,
          });
        },
      });
  }
}
