import { Service } from "typedi";
import { DomMethods } from "../../../../../application/core/shared/dom-methods/DomMethods";
import { IApplePayButtonFactory } from "./IApplePayButtonFactory";

@Service()
export class OfficialApplePayButtonFactory implements IApplePayButtonFactory {
  public create(label: string, style: string, locale: string): HTMLElement {
    return DomMethods.createHtmlElement.apply(this, [
      {
        style: `
                  -webkit-appearance: -apple-pay-button;
                  -apple-pay-button-type: ${label ? label : "plain"};
                  -apple-pay-button-style: ${
                    style ? style : "black"
                  };pointer-events: auto;cursor: pointer;display: flex;role: button;`,
        lang: locale,
      },
      "a",
    ]);
  }
}
