export class ValueUtils {
  static getValueOrDefault<T>(value: T | undefined, defaultValue: T): T {
    switch (typeof value) {
      case "undefined":
        return defaultValue;
      case "string":
        return value.length ? value : defaultValue;
      case "number":
        return Number.isFinite(value) ? value : defaultValue;
      case "boolean":
        return value;
      case "object":
        if (value === null) {
          return defaultValue;
        }
        if (Array.isArray(value)) {
          return value.length ? value : defaultValue;
        }
        return Object.keys(value).length ? value : defaultValue;
      default:
        return value ? value : defaultValue;
    }
  }
}
