export enum APMPayloadFields {
  BILLING_COUNTRY_ISO2A = "billingcountryiso2a",
  CURRENCY_ISO3A = "currencyiso3a",
  BILLING_DOB = "billingdob",
  ORDER_REFERENCE = "orderreference",
  ACCCOUNT_TYPE_DESCRIPTION = "accounttypedescription",
  BASE_AMOUNT = "baseamount",
  MAIN_AMOUNT = "mainamount",
  REQUEST_TYPE_DESCRIPTIONS = "requesttypedescriptions",
  SITE_REFERENCE = "sitereference",
  BILLING_FIRST_NAME = "billingfirstname",
  BILLING_LAST_NAME = "billinglastname",
  BILLING_PREMISE = "billingpremise",
  BILLING_STREET = "billingstreet",
  BILLING_TOWN = "billingtown",
  BILLING_POSTCODE = "billingpostcode",
  BILLING_COUNTY = "billingcounty",
  BILLING_EMAIL = "billingemail",
}
