import { Service } from "typedi";

const applepayOverlayId = "st-apple-pay-overlay";

@Service()
export class ApplePayOverlayService {
  public show(parent: HTMLElement) {
    if (!parent) {
      console.error("Invalid parent element provided.");
      return;
    }

    // Ensure no existing overlay before creating a new one
    if (document.getElementById(applepayOverlayId)) {
      return;
    }

    const applePayOverlay = document.createElement("div");
    applePayOverlay.setAttribute("id", applepayOverlayId);
    applePayOverlay.classList.add(applepayOverlayId);
    parent.appendChild(applePayOverlay);
  }

  public hide() {
    const overlay = document.getElementById(applepayOverlayId);
    if (overlay) {
      overlay.remove();
    } else {
      console.warn("No Apple Pay overlay found to remove.");
    }
  }
}
