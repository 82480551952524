import { Service } from "typedi";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { EventScope } from "../../../client/integrations/constants/EventScope";
import { PAYPAL_TOKEN_PUBLISHER_TOKEN } from "../../../client/dependency-injection/InjectionTokens";
import { IPayPalToken } from "../models/IPayPalToken";
import { IPayPalTokenPublisher } from "./IPayPalTokenPublisher";

/**
 * Publishes a PayPal order ID onto the message bus to be picked up by MessageBusPayPalTokenSource.
 */
@Service({ id: PAYPAL_TOKEN_PUBLISHER_TOKEN, multiple: true })
export class MessageBusPayPalTokenPublisher implements IPayPalTokenPublisher {
  constructor(private readonly messageBus: IMessageBus) {}
  publish(token: IPayPalToken): void {
    this.messageBus.publish(
      {
        type: PUBLIC_EVENTS.PAYPAL_CREATE_ORDER_RESPONSE,
        data: token.orderId,
      },
      EventScope.ALL_FRAMES,
    );
  }
}
