import { PayPalNamespace } from "@paypal/paypal-js";
import { IPayPalConfig } from "../models/IPayPalConfig";

/**
 * Allows us to inject an implementation of the PayPalNamespace into the code.
 *
 * This interface is important for giving us the flexibility to mock out the PayPal implementation.
 */
export abstract class IPayPalInjector {
  abstract inject(
    config: IPayPalConfig,
    liveStatus: number,
  ): Promise<PayPalNamespace | null>;
}
