import { ValidationErrorItem, ValidationResult } from "joi";
import { ContainerInstance, Service } from "typedi";
import { IConfig } from "../../model/config/IConfig";
import { IComponentsIds } from "../../model/config/IComponentsIds";
import { IComponentsConfig } from "../../model/config/IComponentsConfig";
import { DEFAULT_SUBMIT_FIELDS } from "../../../application/core/models/constants/config-resolver/DefaultSubmitFields";
import { DEFAULT_COMPONENTS_IDS } from "../../../application/core/models/constants/config-resolver/DefaultComponentsIds";
import { DEFAULT_CONFIG } from "../../../application/core/models/constants/config-resolver/DefaultConfig";
import { DEFAULT_COMPONENTS } from "../../../application/core/models/constants/config-resolver/DefaultComponents";
import { IPlaceholdersConfig } from "../../../application/core/models/IPlaceholdersConfig";
import { DEFAULT_PLACEHOLDERS } from "../../../application/core/models/constants/config-resolver/DefaultPlaceholders";
import { ENVIRONMENT } from "../../../environments/environment";
import { IGooglePayConfig } from "../../../integrations/google-pay/models/IGooglePayConfig";
import { ConfigValidator } from "../config-validator/ConfigValidator";
import { IApplePayConfig } from "../../../integrations/apple-pay/client/models/IApplePayConfig";
import { TokenizedCardPaymentConfigName } from "../../../integrations/tokenized-card/models/ITokenizedCardPaymentMethod";
import { JwtDecoder } from "../jwt-decoder/JwtDecoder";
import { IStJwtPayload } from "../../../application/core/models/IStJwtPayload";
import { InternalsMonitor } from "../../../application/core/services/monitoring/InternalsMonitor";
import { IPayPalConfig } from "../../../integrations/paypal/models/IPayPalConfig";
import { PayPalCallbackMerger } from "../../../integrations/paypal/client/PayPalCallbackMerger";
import { MisconfigurationError } from "../../../application/core/services/monitoring/sentry/error/MisconfigurationError";
import { StyleConfigAdaptor } from "../../../application/core/shared/styler/StyleConfigAdaptor";
import { ValueUtils } from "../../../application/core/shared/utils/ValueUtils";

@Service()
export class ConfigResolver {
  private config: IConfig;
  constructor(
    private configValidator: ConfigValidator,
    private container: ContainerInstance,
  ) {}

  resolve(config: IConfig): IConfig {
    this.validate(config);
    const validatedConfig: IConfig = {
      analytics: ValueUtils.getValueOrDefault(
        config.analytics,
        DEFAULT_CONFIG.analytics,
      ),
      animatedCard: ValueUtils.getValueOrDefault(
        config.animatedCard,
        DEFAULT_CONFIG.animatedCard,
      ),
      applePay: this.setApplePayConfig(config.applePay),
      buttonId: ValueUtils.getValueOrDefault(
        config.buttonId,
        DEFAULT_CONFIG.buttonId,
      ),
      stopSubmitFormOnEnter: ValueUtils.getValueOrDefault(
        config.stopSubmitFormOnEnter,
        DEFAULT_CONFIG.stopSubmitFormOnEnter,
      ),
      cancelCallback: ValueUtils.getValueOrDefault(
        config.cancelCallback,
        DEFAULT_CONFIG.cancelCallback,
      ),
      componentIds: this.setComponentIds(config.componentIds),
      components: this.setComponentsProperties(config.components),
      datacenterurl: ValueUtils.getValueOrDefault(
        config.datacenterurl,
        DEFAULT_CONFIG.datacenterurl,
      ),
      deferInit: ValueUtils.getValueOrDefault(
        config.deferInit,
        DEFAULT_CONFIG.deferInit,
      ),
      disableNotification: ValueUtils.getValueOrDefault(
        config.disableNotification,
        DEFAULT_CONFIG.disableNotification,
      ),
      errorCallback: ValueUtils.getValueOrDefault(
        config.errorCallback,
        DEFAULT_CONFIG.errorCallback,
      ),
      errorReporting: ValueUtils.getValueOrDefault(
        config.errorReporting,
        DEFAULT_CONFIG.errorReporting,
      ),
      fieldsToSubmit: ValueUtils.getValueOrDefault(
        config.fieldsToSubmit,
        DEFAULT_CONFIG.fieldsToSubmit,
      ),
      formId: ValueUtils.getValueOrDefault(
        config.formId,
        DEFAULT_CONFIG.formId,
      ),
      googlePay: this.setGooglePayConfig(config.googlePay, config.jwt),
      paypal: this.setPayPalConfig(config.paypal),
      init: ValueUtils.getValueOrDefault(config.init, DEFAULT_CONFIG.init),
      jwt: ValueUtils.getValueOrDefault(config.jwt, DEFAULT_CONFIG.jwt),
      livestatus: ValueUtils.getValueOrDefault(
        config.livestatus,
        DEFAULT_CONFIG.livestatus,
      ),
      origin: ValueUtils.getValueOrDefault(
        config.origin,
        DEFAULT_CONFIG.origin,
      ),
      panIcon: ValueUtils.getValueOrDefault(
        config.panIcon,
        DEFAULT_CONFIG.panIcon,
      ),
      placeholders: this.setPlaceholders(config.placeholders),
      styles: StyleConfigAdaptor.adaptStylesConfig(
        ValueUtils.getValueOrDefault(config.styles, DEFAULT_CONFIG.styles),
      ),
      submitCallback: ValueUtils.getValueOrDefault(
        config.submitCallback,
        DEFAULT_CONFIG.submitCallback,
      ),
      submitFields: ValueUtils.getValueOrDefault(
        config.submitFields,
        DEFAULT_SUBMIT_FIELDS,
      ),
      submitOnCancel: ValueUtils.getValueOrDefault(
        config.submitOnCancel,
        false,
      ),
      submitOnError: ValueUtils.getValueOrDefault(
        config.submitOnError,
        DEFAULT_CONFIG.submitOnError,
      ),
      submitOnSuccess: ValueUtils.getValueOrDefault(
        config.submitOnSuccess,
        DEFAULT_CONFIG.submitOnSuccess,
      ),
      successCallback: ValueUtils.getValueOrDefault(
        config.successCallback,
        DEFAULT_CONFIG.successCallback,
      ),
      disabledAutoPaymentStart: ValueUtils.getValueOrDefault(
        config.disabledAutoPaymentStart,
        DEFAULT_CONFIG.disabledAutoPaymentStart,
      ),
      translations: ValueUtils.getValueOrDefault(
        config.translations,
        DEFAULT_CONFIG.translations,
      ),
      [TokenizedCardPaymentConfigName]: {
        ...DEFAULT_CONFIG[TokenizedCardPaymentConfigName],
        ...config[TokenizedCardPaymentConfigName],
      },
    };
    if (!ENVIRONMENT.production) {
      console.error(validatedConfig);
    }
    return validatedConfig;
  }

  private validate(config: IConfig): void {
    if (!config) {
      // Using static, because ConfigResolver is loaded before the container registration
      InternalsMonitor.getInstance().recordIssue(
        new Error("undefined or empty merchant configuration"),
      );
    }
    this.config = config;
    const validationResult: ValidationResult =
      this.configValidator.validate(config);

    if (validationResult.error) {
      const error = new MisconfigurationError(
        `Misconfiguration: ${validationResult.error.message}`,
        validationResult.error,
      );

      // Using static, because ConfigResolver is loaded before the container registration
      InternalsMonitor.getInstance().recordIssue(error, this.config);
      throw validationResult.error;
    }

    if (validationResult.warning) {
      validationResult.warning.details.forEach((item: ValidationErrorItem) => {
        console.warn(item.message);
        this.handleValidationExceptions(item);
      });
    }
  }

  private setApplePayConfig(
    config: IApplePayConfig,
  ): IApplePayConfig | undefined {
    if (!config || !Object.keys(config).length) {
      return undefined;
    }
    return config;
  }

  /**
   * Validates the Google Pay configuration and sets the shippingAddressRequired and
   * billingAddressRequired fields based on the values in the JWT.
   *
   * @param {IGooglePayConfig} googlePayConfig The Google Pay configuration to validate.
   * @param {string} jwt The JWT containing the customer contact details override.
   * @returns {IGooglePayConfig} The validated Google Pay configuration.
   *
   * @description Validates the shippingAddressRequired and billingAddressRequired
   * fields in the Google Pay configuration based on the values in the
   * JWT(customercontactdetailsoverride, billingcontactdetailsoverride).
   */
  private setGooglePayConfig(
    googlePayConfig: IGooglePayConfig,
    jwt: string,
  ): IGooglePayConfig | undefined {
    if (!googlePayConfig || !Object.keys(googlePayConfig).length) {
      return undefined;
    }

    const { payload }: { payload: IStJwtPayload } = this.container
      .get(JwtDecoder)
      .decode(jwt);

    // shippingAddressRequired filed validation
    if (
      googlePayConfig.paymentRequest?.shippingAddressRequired &&
      payload?.customercontactdetailsoverride != "1"
    ) {
      console.warn(
        "shippingAddressRequired can not be enabled without also specifying 'customercontactdetailsoverride=1' in your JWT. Defaulting to false",
      );
      googlePayConfig.paymentRequest.shippingAddressRequired = false;
    }

    // billingAddressRequired filed validation
    const billingAddressRequired =
      googlePayConfig.paymentRequest?.allowedPaymentMethods?.find(
        (method) => method.parameters.billingAddressRequired,
      );

    if (
      billingAddressRequired &&
      payload?.billingcontactdetailsoverride != "1"
    ) {
      console.warn(
        "billingAddressRequired can not be enabled without also specifying 'billingcontactdetailsoverride=1' in your JWT. Defaulting to false",
      );
      googlePayConfig.paymentRequest.allowedPaymentMethods.forEach(
        (paymentMethod) => {
          if (paymentMethod.parameters?.billingAddressRequired) {
            paymentMethod.parameters.billingAddressRequired = false;
          }
        },
      );
    }

    return googlePayConfig;
  }

  private setPayPalConfig(
    paypalConfig: IPayPalConfig,
  ): IPayPalConfig | undefined {
    if (!paypalConfig || !Object.keys(paypalConfig).length) {
      return undefined;
    }

    // debug field validation
    if (paypalConfig.scriptConfig.debug) {
      console.warn(
        "PayPal debug mode is enabled. Usage in production is highly discouraged.",
      );
    }

    paypalConfig.callbacks = new PayPalCallbackMerger().merge(
      paypalConfig.callbacks ?? {},
    );

    return paypalConfig;
  }

  private setComponentIds(config: IComponentsIds): IComponentsIds {
    if (!config || !Object.keys(config).length) {
      return DEFAULT_COMPONENTS_IDS;
    }
    return {
      animatedCard: ValueUtils.getValueOrDefault(
        config.animatedCard,
        DEFAULT_COMPONENTS_IDS.animatedCard,
      ),
      cardNumber: ValueUtils.getValueOrDefault(
        config.cardNumber,
        DEFAULT_COMPONENTS_IDS.cardNumber,
      ),
      expirationDate: ValueUtils.getValueOrDefault(
        config.expirationDate,
        DEFAULT_COMPONENTS_IDS.expirationDate,
      ),
      notificationFrame: ValueUtils.getValueOrDefault(
        config.notificationFrame,
        DEFAULT_COMPONENTS_IDS.notificationFrame,
      ),
      securityCode: ValueUtils.getValueOrDefault(
        config.securityCode,
        DEFAULT_COMPONENTS_IDS.securityCode,
      ),
    };
  }

  private setComponentsProperties(
    config: IComponentsConfig,
  ): IComponentsConfig {
    if (!config || !Object.keys(config).length) {
      return DEFAULT_COMPONENTS;
    }
    return {
      defaultPaymentType: ValueUtils.getValueOrDefault(
        config.defaultPaymentType,
        DEFAULT_COMPONENTS.defaultPaymentType,
      ),
      paymentTypes: ValueUtils.getValueOrDefault(
        config.paymentTypes,
        DEFAULT_COMPONENTS.paymentTypes,
      ),
      startOnLoad: ValueUtils.getValueOrDefault(
        config.startOnLoad,
        DEFAULT_COMPONENTS.startOnLoad,
      ),
    };
  }

  private setPlaceholders(config: IPlaceholdersConfig): IPlaceholdersConfig {
    if (!config || !Object.keys(config).length) {
      return DEFAULT_PLACEHOLDERS;
    }
    return {
      pan: ValueUtils.getValueOrDefault(config.pan, DEFAULT_PLACEHOLDERS.pan),
      expirydate: ValueUtils.getValueOrDefault(
        config.expirydate,
        DEFAULT_PLACEHOLDERS.expirydate,
      ),
      securitycode: ValueUtils.getValueOrDefault(
        config.securitycode,
        DEFAULT_PLACEHOLDERS.securitycode,
      ),
    };
  }

  private handleValidationExceptions(item: ValidationErrorItem): void {
    if (item?.path?.toString() === "datacenterurl") {
      const error = new Error(
        `Invalid ${item?.context?.key} config value: ${item?.context?.value}`,
      );

      // Using static, because ConfigResolver is loaded before the container registration
      InternalsMonitor.getInstance().recordIssue(error, this.config);
    }
    if (item?.type === "deprecate.error") {
      if (
        JSON.stringify(item?.path) !== JSON.stringify(["applePay", "placement"])
      ) {
        const error = new MisconfigurationError(
          `Misconfiguration: ${item?.message}`,
        );

        // Using static, because ConfigResolver is loaded before the container registration
        InternalsMonitor.getInstance().recordIssue(error, this.config);
      }
    }
  }
}
