import { IHttpClientConfig } from "@trustpayments/http-client";
import { Service } from "typedi";
import { IRequestObject } from "../../../models/IRequestObject";
import { ENVIRONMENT } from "../../../../../environments/environment";
import { IHttpOptionsProvider } from "./IHttpOptionsProvider";

@Service()
export class DefaultHttpOptionsProvider implements IHttpOptionsProvider {
  static readonly CONTENT_TYPE = "application/json";

  getOptions(requestObject: IRequestObject): IHttpClientConfig {
    return {
      headers: {
        Accept: DefaultHttpOptionsProvider.CONTENT_TYPE,
        "Content-Type": DefaultHttpOptionsProvider.CONTENT_TYPE,
      },
      timeout: ENVIRONMENT.REQUEST_TIMEOUT,
    };
  }
}
